import { axiosPrivate } from "../../../api/axiosInstance";
import {
  GET_RAFFLE_WINNER,
  GET_PAST_AUCTION_WINNER,
  GET_PAST_RAFFLE_WINNER,
} from "../../../api/endpoints";

export const getRaffleWinnerService = async () => {
  const res = await axiosPrivate.get(`${GET_RAFFLE_WINNER}`);

  return res?.data;
};

export const getPastRaffleWinnerService = async () => {
  const res = await axiosPrivate.get(`${GET_PAST_RAFFLE_WINNER}`);

  return res?.data;
};

export const getPastAuctionService = async () => {
  const res = await axiosPrivate.get(`${GET_PAST_AUCTION_WINNER}`);

  return res?.data;
};
