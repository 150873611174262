import Footer from "../../components/footer/footer";
import logo from "../../assets/images/logo.png";
import logo2 from "../../assets/images/logo2.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ForgetPasswordSchema } from "../../util/validation/validation";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import PhoneInput from "react-phone-number-input";
import {
  forgetPasswordT,
  resetForgetPasswordSuccess,
} from "../../redux/slice/user";
import { Bars } from "react-loader-spinner";
import { useEffect } from "react";

function ForgetPasswordNext() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoadingForgetPassword, forgetPasswordSuccess } = useSelector(
    (state) => state.user
  );
  const data = location.state;

  const initialValues = {
    phonenumber: "",
  };

  useEffect(() => {
    dispatch(resetForgetPasswordSuccess());
  }, []);

  useEffect(() => {
    if (forgetPasswordSuccess) {
      navigate("/forget-password-success");
    }
  }, [forgetPasswordSuccess]);

  const submitForm = async (values) => {
    const payload = {
      phoneNumber: values?.phonenumber,
      isMtn: data?.isMtn,
    };

    await dispatch(forgetPasswordT(payload));
  };

  return (
    <>
      <section className="section-pd">
        <div className="section-pd text-center">
          <a href="/">
            <img src={logo} srcSet={`${logo2} 2x`} alt="Zanobid" />
          </a>
        </div>
        <div className="">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-4 col-11">
                <div className="za-bg-white p-3 kab-login">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={ForgetPasswordSchema}
                    onSubmit={submitForm}
                  >
                    {(formik) => {
                      const { isValid, dirty } = formik;

                      return (
                        <Form>
                          <h3 className="mb-1 fw-bold">Recover Username</h3>
                          <div className="mb-3">
                            <p className="small">
                              Your username will be sent to your Phone number
                            </p>
                          </div>
                          <div className="mb-3">
                            <label>Phone number</label>
                            <div class="mb-3">
                              <Field name="phonenumber">
                                {({ field, form }) => (
                                  <>
                                    <PhoneInput
                                      {...field}
                                      placeholder="Enter phone number"
                                      onChange={(value) =>
                                        form.setFieldValue("phonenumber", value)
                                      }
                                      defaultCountry="NG"
                                    />
                                    <ErrorMessage
                                      name="phonenumber"
                                      component="span"
                                      className="error"
                                    />
                                  </>
                                )}
                              </Field>
                            </div>
                          </div>
                          <div className="d-grid mb-3">
                            <button
                              disabled={!(dirty && isValid)}
                              className="btn btn-primary btn-lg kab-bg-midred border-0 fw-bold"
                              type="submit"
                            >
                              {isLoadingForgetPassword ? (
                                <div className="d-flex justify-content-center">
                                  <Bars
                                    height="30"
                                    width="30"
                                    color="#FFF"
                                    ariaLabel="bars-loading"
                                    wrapperclassName=""
                                    visible={true}
                                  />
                                </div>
                              ) : (
                                "Recover Username"
                              )}
                            </button>
                          </div>
                          <div className="mb-4">
                            <p className="small">
                              By continuing, you agree to zanobid's <br />
                              <a href="terms.html">
                                Conditions of Use
                              </a> and{" "}
                              <a href="privacy-policy.html">Privacy Notice</a>
                            </p>
                          </div>
                          <hr />
                          <div className="mb-3">
                            <p>
                              New to zanobid?{" "}
                              <Link to="/payment-method">
                                Create account now ›
                              </Link>
                            </p>
                          </div>
                          <div className="mb-3">
                            <p>
                              Remember your details?{" "}
                              <Link to="/login">Login Now ›</Link>
                            </p>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ForgetPasswordNext;
