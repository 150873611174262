import { axiosInstance, axiosPrivate } from "../../../api/axiosInstance";
import {
  LOGIN,
  SIGNUP,
  GET_SUBSCRIPTION_PLAN,
  INITIATE_SUBSCRIPTION,
  SUBSCRIPTION_DETAILS,
  FORGOT_PASSWORD,
} from "../../../api/endpoints";

export const loginService = async (data) => {
  const res = await axiosInstance.post(LOGIN, data);

  return res?.data;
};

export const signupService = async (data) => {
  const res = await axiosInstance.post(SIGNUP, data);

  return res?.data;
};

export const getSubscriptionsPlanService = async () => {
  const res = await axiosInstance.get(GET_SUBSCRIPTION_PLAN);

  return res?.data;
};

export const forgetPasswordService = async (data) => {
  const res = await axiosInstance.post(FORGOT_PASSWORD, data);

  return res?.data;
};

export const initiateSubscriptionsService = async (body) => {
  const res = await axiosPrivate.post(INITIATE_SUBSCRIPTION, body);

  return res?.data;
};

export const SubscriptionDetailsService = async () => {
  const res = await axiosPrivate.get(SUBSCRIPTION_DETAILS);

  return res?.data;
};
