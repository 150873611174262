import React from "react";
import Samsung_Galaxy_A03 from "../../assets/images/products/Samsung_Galaxy_A03.jpg";
import HP_Intel from "../../assets/images/products/HP_Intel.jpg";
import Galaxy_A12 from "../../assets/images/products/Galaxy-A12.png";
import Lg_HomeTheatre2 from "../../assets/images/products/Lg_HomeTheatre2.jpg";
import Hisense_Washing_Machine from "../../assets/images/products/Hisense_Washing_Machine.jpg";
import iphone11 from "../../assets/images/products/iphone-11.png";
import Galaxy_A32 from "../../assets/images/products/Galaxy-A32.jpg";
import Hisesne_Air_conditioner from "../../assets/images/products/Hisesne_Air_conditioner.jpg";
import PS5 from "../../assets/images/products/PS5.jpg";
import maxi_cooker from "../../assets/images/products/maxi-cooker.jpg";
import iphone11pro from "../../assets/images/products/iphone-11-pro.jpeg";
import LG_microwave from "../../assets/images/products/LG_microwave.jpg";
import A22 from "../../assets/images/products/A22.jpg";
import iphone12 from "../../assets/images/products/iphone-12-family-select-2021.jpeg";
import Sumec_Generator from "../../assets/images/products/Sumec_Generator.jpg";
import Dell_latitude from "../../assets/images/products/Dell-latitude.png";
import iphone12ProMax from "../../assets/images/products/iPhone-12-Pro-Max.png";
import LG_refrigerator from "../../assets/images/products/LG-refrigerator.jpg";
import iphone12Pro from "../../assets/images/products/iphone12Pro.png";
import Hp_Spectre from "../../assets/images/products/Hp_Spectre.jpg";
import iPhone13 from "../../assets/images/products/iPhone-13.png";
import iPhone13pro from "../../assets/images/products/IPhone13_pro.jpg";
import JBL3 from "../../assets/images/products/Jbl_Go3.jpg";
import Iphone13proMax from "../../assets/images/products/iPhone-13-Pro-Max-539.jpg";
import iphone12mini from "../../assets/images/products/iphone-12-mini.png";
import MacBookAir2021 from "../../assets/images/products/MacBookAir2021.jpeg";
import JBLClip4 from "../../assets/images/products/JBLClip4.jpg";
import iphone11proMax from "../../assets/images/products/iPhone11_pro_max.jpg";
import macbook from "../../assets/images/products/macbook_13_2018.0.png";
import LG from "../../assets/images/products/LG-tv.jpg";
import Iphone13pro from "../../assets/images/products/IPhone13_pro.jpg";
import JBL from "../../assets/images/products/JBL_xtreme_3.jpg";
import lexus from "../../assets/images/products/lexusRx350.jpeg";
import Dell_Alienware from "../../assets/images/products/Dell_Alienware.jpeg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function Upcoming() {
  const { accesstoken } = useSelector((state) => state.user);
  return (
    <div className="upcoming-items section-pd bg-white">
      <div className="container">
        <div className="kab-tile-header mb-3">
          <h1 className="fw-bold m-0">Upcoming</h1>
          <button
            className="btn btn-primary amazon-primary-button border-0"
            type="button"
          >
            {accesstoken ? (
              "Get Ready"
            ) : (
              <Link
                className="amazon-primary-button text-decoration-none"
                to="/payment-method"
              >
                Access All Now
              </Link>
            )}
          </button>
        </div>
        <div className="row g-2">
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Samsung_Galaxy_A03} />
              </div>
              <h6 className="prod-name">Galaxy A03 Core</h6>
              <p className="prod-date">1st April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={HP_Intel} />
              </div>
              <h6 className="prod-name">HP Intel Laptop</h6>
              <p className="prod-date">1st April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={macbook} />
                <div className="kab-bg-midred premium-prod za-white">
                  SPONSORED
                </div>
              </div>
              <h6 className="prod-name">Macbook Pro 2021</h6>
              <p className="prod-date">1st April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={LG} />
              </div>
              <h6 className="prod-name">LG 50" Smart TV</h6>
              <p className="prod-date">1st April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Galaxy_A12} />
              </div>
              <h6 className="prod-name">Galaxy A12</h6>
              <p className="prod-date">2nd April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Lg_HomeTheatre2} />
              </div>
              <h6 className="prod-name">LG Home Theatre</h6>
              <p className="prod-date">2nd April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Hisense_Washing_Machine} />
              </div>
              <h6 className="prod-name">Hisense Washer</h6>
              <p className="prod-date">2nd April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={iphone11} />
              </div>
              <h6 className="prod-name">iPhone 11</h6>
              <p className="prod-date">2nd April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Iphone13pro} />
                <div className="kab-bg-midred premium-prod za-white">
                  SPONSORED
                </div>
              </div>
              <h6 className="prod-name">iPhone 13 Pro</h6>
              <p className="prod-date">3rd April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Galaxy_A32} />
              </div>
              <h6 className="prod-name">Galaxy A32</h6>
              <p className="prod-date">3rd April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Hisesne_Air_conditioner} />
              </div>
              <h6 className="prod-name">Hisense AC</h6>
              <p className="prod-date">3rd April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Samsung_Galaxy_A03} />
              </div>
              <h6 className="prod-name">Galaxy A03 Core</h6>
              <p className="prod-date">4th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={PS5} />
              </div>
              <h6 className="prod-name">Play Station 5</h6>
              <p className="prod-date">4th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={maxi_cooker} />
              </div>
              <h6 className="prod-name">Maxi Gas Cooker</h6>
              <p className="prod-date">4th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={iphone11pro} />
              </div>
              <h6 className="prod-name">iPhone 11 Pro Max</h6>
              <p className="prod-date">5th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Galaxy_A12} />
              </div>
              <h6 className="prod-name">Galaxy A12</h6>
              <p className="prod-date">5th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={LG_microwave} />
              </div>
              <h6 className="prod-name">LG NeoChef</h6>
              <p className="prod-date">5th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={A22} />
              </div>
              <h6 className="prod-name">Galaxy A22</h6>
              <p className="prod-date">6th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={iphone12} />
              </div>
              <h6 className="prod-name">iPhone 12</h6>
              <p className="prod-date">6th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Sumec_Generator} />
              </div>
              <h6 className="prod-name">Sumec Generator</h6>
              <p className="prod-date">6th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Dell_latitude} />
              </div>
              <h6 className="prod-name">Dell Latitude</h6>
              <p className="prod-date">7th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Samsung_Galaxy_A03} />
              </div>
              <h6 className="prod-name">Galaxy A03 Core</h6>
              <p className="prod-date">7th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={LG} />
              </div>
              <h6 className="prod-name">LG 50" Smart TV</h6>
              <p className="prod-date">7th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={iphone12ProMax} />
                <div className="kab-bg-midred premium-prod za-white">
                  SPONSORED
                </div>
              </div>
              <h6 className="prod-name">iPhone 12 Pro Max</h6>
              <p className="prod-date">8th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={LG_refrigerator} />
              </div>
              <h6 className="prod-name">LG Refrigerator</h6>
              <p className="prod-date">8th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={JBL} />
              </div>
              <h6 className="prod-name">JBL xtreme 3</h6>
              <p className="prod-date">8th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Galaxy_A12} />
              </div>
              <h6 className="prod-name">Galaxy A12</h6>
              <p className="prod-date">8th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={iPhone13pro} />
                <div className="kab-bg-midred premium-prod za-white">
                  SPONSORED
                </div>
              </div>
              <h6 className="prod-name">iPhone 13 Pro Max</h6>
              <p className="prod-date">9th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Galaxy_A32} />
              </div>
              <h6 className="prod-name">Galaxy A32</h6>
              <p className="prod-date">9th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Hisesne_Air_conditioner} />
              </div>
              <h6 className="prod-name">Hisense AC</h6>
              <p className="prod-date">9th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={iphone11pro} />
              </div>
              <h6 className="prod-name">iPhone 11 Pro</h6>
              <p className="prod-date">9th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={iphone12Pro} />
                <div className="kab-bg-midred premium-prod za-white">
                  SPONSORED
                </div>
              </div>
              <h6 className="prod-name">iPhone 13 Pro</h6>
              <p className="prod-date">10th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Samsung_Galaxy_A03} />
              </div>
              <h6 className="prod-name">Galaxy A03 Core</h6>
              <p className="prod-date">10th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Hp_Spectre} />
                <div className="kab-bg-midred premium-prod za-white">
                  SPONSORED
                </div>
              </div>
              <h6 className="prod-name">HP Spectre</h6>
              <p className="prod-date">10th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={maxi_cooker} />
              </div>
              <h6 className="prod-name">Maxi Gas Cookers</h6>
              <p className="prod-date">10th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={iphone12Pro} />
                <div className="kab-bg-midred premium-prod za-white">
                  SPONSORED
                </div>
              </div>
              <h6 className=" prod-name">iPhone 12 Pro</h6>
              <p className="prod-date">11th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Galaxy_A12} />
              </div>
              <h6 className="prod-name">Galaxy A12</h6>
              <p className="prod-date">11th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Hisense_Washing_Machine} />
              </div>
              <h6 className="prod-name">Hisense Washer</h6>
              <p className="prod-date">11th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={A22} />
              </div>
              <h6 className="prod-name">Galaxy A22</h6>
              <p className="prod-date">12th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={iPhone13} />
                <div className="kab-bg-midred premium-prod za-white">
                  SPONSORED
                </div>
              </div>
              <h6 className="prod-name">iPhone 13</h6>
              <p className="prod-date">12th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Sumec_Generator} />
              </div>
              <h6 className="prod-name">Sumec Generator</h6>
              <p className="prod-date">12th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={LG} />
              </div>
              <h6 className="prod-name">LG 50" Smart TV</h6>
              <p className="prod-date">13th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Samsung_Galaxy_A03} />
              </div>
              <h6 className="prod-name">Galaxy A03 Core</h6>
              <p className="prod-date">13th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={HP_Intel} />
              </div>
              <h6 className="prod-name">HP Intel Laptop</h6>
              <p className="prod-date">13th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Galaxy_A12} />
              </div>
              <h6 className="prod-name">Galaxy A12</h6>
              <p className="prod-date">14th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={JBL3} />
              </div>
              <h6 className="prod-name">JBL Go 3</h6>
              <p className="prod-date">14th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={LG_microwave} />
              </div>
              <h6 className="prod-name">LG NeoChef</h6>
              <p className="prod-date">14th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={iphone12ProMax} />
                <div className="kab-bg-midred premium-prod za-white">
                  SPONSORED
                </div>
              </div>
              <h6 className="prod-name">iPhone 12 Pro Max</h6>
              <p className="prod-date">15th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Hisesne_Air_conditioner} />
              </div>
              <h6 className="prod-name">Hisense AC</h6>
              <p className="prod-date">15th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Galaxy_A32} />
              </div>
              <h6 className="prod-name">Galaxy A32</h6>
              <p className="prod-date">15th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Iphone13proMax} />
                <div className="kab-bg-midred premium-prod za-white">
                  SPONSORED
                </div>
              </div>
              <h6 className="prod-name">iPhone 13 Pro Max</h6>
              <p className="prod-date">16th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Samsung_Galaxy_A03} />
              </div>
              <h6 className="prod-name">Galaxy A03 Core</h6>
              <p className="prod-date">16th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={maxi_cooker} />
              </div>
              <h6 className="prod-name">Maxi Gas Cookers</h6>
              <p className="prod-date">16th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={iphone12mini} />
              </div>
              <h6 className="prod-name">iPhone 13 Mini</h6>
              <p className="prod-date">16th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={iPhone13pro} />
                <div className="kab-bg-midred premium-prod za-white">
                  SPONSORED
                </div>
              </div>
              <h6 className="prod-name">iPhone 13 Pro</h6>
              <p className="prod-date">17th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Galaxy_A12} />
              </div>
              <h6 className="prod-name">Galaxy A12</h6>
              <p className="prod-date">17th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Hisense_Washing_Machine} />
              </div>
              <h6 className="prod-name">Hisense Washer</h6>
              <p className="prod-date">17th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={lexus} />
                <div className="kab-bg-midred premium-prod za-white">
                  SPONSORED
                </div>
              </div>
              <h6 className="prod-name">Lexus RX350 SUV</h6>
              <p className="prod-date">17th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={iphone12} />
              </div>
              <h6 className="prod-name">iPhone 12</h6>
              <p className="prod-date">18th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={A22} />
              </div>
              <h6 className="prod-name">Galaxy A22</h6>
              <p className="prod-date">18th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Sumec_Generator} />
              </div>
              <h6 className="prod-name">Sumec Generator</h6>
              <p className="prod-date">18th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Samsung_Galaxy_A03} />
              </div>
              <h6 className="prod-name">Galaxy A03 Core</h6>
              <p className="prod-date">19th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={LG} />
              </div>
              <h6 className="prod-name">LG 50" Smart TV</h6>
              <p className="prod-date">19th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Dell_latitude} />
              </div>
              <h6 className="prod-name">Dell Latitude</h6>
              <p className="prod-date">19th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Lg_HomeTheatre2} />
              </div>
              <h6 className="prod-name">LG Home Theatre</h6>
              <p className="prod-date">20th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Galaxy_A12} />
              </div>
              <h6 className="prod-name">Galaxy A12</h6>
              <p className="prod-date">20th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Hisense_Washing_Machine} />
              </div>
              <h6 className="prod-name">Hisense Washer</h6>
              <p className="prod-date">20th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Galaxy_A32} />
              </div>
              <h6 className="prod-name">Galaxy A32</h6>
              <p className="prod-date">21st April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Hisense_Washing_Machine} />
              </div>
              <h6 className="prod-name">Hisense AC</h6>
              <p className="prod-date">21st April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={PS5} />
              </div>
              <h6 className="prod-name">Play Station 5</h6>
              <p className="prod-date">21st April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={iphone12ProMax} />
                <div className="kab-bg-midred premium-prod za-white">
                  SPONSORED
                </div>
              </div>
              <h6 className="prod-name">iPhone 12 Pro Max</h6>
              <p className="prod-date">22nd April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={maxi_cooker} />
              </div>
              <h6 className="prod-name">Maxi Gas Cooker</h6>
              <p className="prod-date">22nd April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Samsung_Galaxy_A03} />
              </div>
              <h6 className="prod-name">Galaxy A03 Core</h6>
              <p className="prod-date">22nd April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Iphone13proMax} />
                <div className="kab-bg-midred premium-prod za-white">
                  SPONSORED
                </div>
              </div>
              <h6 className="prod-name">iPhone 13 Pro Max</h6>
              <p className="prod-date">23rd April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={LG_microwave} />
              </div>
              <h6 className="prod-name">LG NeoChef</h6>
              <p className="prod-date">23rd April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={MacBookAir2021} />
              </div>
              <h6 className="prod-name">Apple MacBook Air</h6>
              <p className="prod-date">23rd April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Galaxy_A12} />
              </div>
              <h6 className="prod-name">Galaxy A12</h6>
              <p className="prod-date">23rd April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Iphone13pro} />
                <div className="kab-bg-midred premium-prod za-white">
                  SPONSORED
                </div>
              </div>
              <h6 className="prod-name">iPhone 13 Pro</h6>
              <p className="prod-date">24th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Dell_Alienware} />
                <div className="kab-bg-midred premium-prod za-white">
                  SPONSORED
                </div>
              </div>
              <h6 className="prod-name">Dell Alienware</h6>
              <p className="prod-date">24th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Sumec_Generator} />
              </div>
              <h6 className="prod-name">Sumec Generator</h6>
              <p className="prod-date">24th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={A22} />
              </div>
              <h6 className="prod-name">Galaxy A22</h6>
              <p className="prod-date">24th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Samsung_Galaxy_A03} />
              </div>
              <h6 className="prod-name">Galaxy A03 Core</h6>
              <p className="prod-date">25th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={LG} />
              </div>
              <h6 className="prod-name">LG 50" Smart TV</h6>
              <p className="prod-date">25th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={HP_Intel} />
              </div>
              <h6 className="prod-name">HP Intel Laptop</h6>
              <p className="prod-date">25th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={JBLClip4} />
              </div>
              <h6 className="prod-name">JBL Clip 4</h6>
              <p className="prod-date">26th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Galaxy_A12} />
              </div>
              <h6 className="prod-name">Galaxy A12</h6>
              <p className="prod-date">26th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Hisense_Washing_Machine} />
              </div>
              <h6 className="prod-name">Hisense Washer</h6>
              <p className="prod-date">26th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Galaxy_A32} />
              </div>
              <h6 className="prod-name">Galaxy A32</h6>
              <p className="prod-date">27th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={iphone12Pro} />
                <div className="kab-bg-midred premium-prod za-white">
                  SPONSORED
                </div>
              </div>
              <h6 className="prod-name">iPhone 12 Pro</h6>
              <p className="prod-date">27th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Hisesne_Air_conditioner} />
              </div>
              <h6 className="prod-name">Hisense AC</h6>
              <p className="prod-date">27th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={iphone11proMax} />
              </div>
              <h6 className="prod-name">iPhone 11 Pro Max</h6>
              <p className="prod-date">28th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Samsung_Galaxy_A03} />
              </div>
              <h6 className="prod-name">Galaxy A03 Core</h6>
              <p className="prod-date">28th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={maxi_cooker} />
              </div>
              <h6 className="prod-name">Maxi Gas Cooker</h6>
              <p className="prod-date">28th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={iphone12ProMax} />
                <div className="kab-bg-midred premium-prod za-white">
                  SPONSORED
                </div>
              </div>
              <h6 className="prod-name">iPhone 12 Pro Max</h6>
              <p className="prod-date">29th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={LG_refrigerator} />
              </div>
              <h6 className="prod-name">LG Refrigerators</h6>
              <p className="prod-date">29th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Galaxy_A12} />
              </div>
              <h6 className="prod-name">Galaxy A12</h6>
              <p className="prod-date">29th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Iphone13proMax} />
                <div className="kab-bg-midred premium-prod za-white">
                  SPONSORED
                </div>
              </div>
              <h6 className="prod-name">iPhone 13 Pro Max</h6>
              <p className="prod-date">30th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={A22} />
              </div>
              <h6 className="prod-name">Galaxy A22</h6>
              <p className="prod-date">30th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={Sumec_Generator} />
              </div>
              <h6 className="prod-name">Sumec Generator</h6>
              <p className="prod-date">30th April 2022</p>
            </div>
          </div>
          <div className="col-4 col-sm-2">
            <div className="kab-product kab-product-list">
              <div className="prod-img">
                <img src={iPhone13} />
                <div className="kab-bg-midred premium-prod za-white">
                  SPONSORED
                </div>
              </div>
              <h6 className="prod-name">iPhone 13</h6>
              <p className="prod-date">30th April 2022</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Upcoming;
