import React from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { useAuth } from "../../hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Flutter } from "../../assets/images/icons/FlutterwaveLogo.svg";
import { ReactComponent as Mtn } from "../../assets/images/icons/MTNLogo.svg";
import { setPayment } from "../../redux/slice/user";
import { useDispatch } from "react-redux";

function PaymentMethod() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMtn = () => {
    navigate("/subscribe");
  };

  const selectPaymentMethod = (name) => {
    dispatch(setPayment(name));
    navigate("/fund-wallet");
  };

  return (
    <div>
      {" "}
      <Header auth={auth} />
      <section style={{ background: "#fff" }} class="za-top-up">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-sm-4">
              <h4 class="main-title">Select Payment Method</h4>

              <a
                onClick={() => selectPaymentMethod("paystack")}
                class="pay-card"
              >
                <div class="row">
                  <div class="col-11 my-auto">
                    <h5> Pay with Paystack </h5>
                  </div>
                  <div class="col-1 p-0 my-auto">
                    <svg
                      width="9"
                      height="14"
                      viewBox="0 0 9 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.299072 11.8768L5.3863 6.78953L0.299072 1.72879L1.86233 0.165527L8.48633 6.78953L1.86233 13.4135L0.299072 11.8768Z"
                        fill="#37475A"
                      />
                    </svg>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PaymentMethod;
