import React, { useEffect, useState } from "react";

import {
  getActiveBidderT,
  getAuctionT,
  getServerTimeT,
  getlastAuctionT,
} from "../../../redux/slice/auction";
import { useDispatch, useSelector } from "react-redux";

import { useAuth } from "../../../hooks/auth";

import GetReadyModal from "../../../components/modal/getReadyModal";
import { Circles } from "react-loader-spinner";
import {
  SubscriptionDetailsT,
  getSubscriptionsPlanT,
} from "../../../redux/slice/user";
import { useNavigate } from "react-router-dom";

import TestGameModal from "../../../components/modal/test-modal";
import ReactGA from "react-ga4";
import Header from "../../../components/header/header";
import Footer from "../../../components/footer/footer";
import { formatAmount, isEmptyObject } from "../../../util/helper";

function ActiveSubscriber() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // modal state
  const {
    auction50,
    auction100,
    auction200,
    auction2k,
    auction5k,
    isLoading,
    serverCurrentTime,
  } = useSelector((state) => state.auction);
  const { subscriptionPlans } = useSelector((state) => state.user);
  const { accesstoken, hasPaidOnce } = useSelector((state) => state.user);
  const { activeSubscriber } = useSelector((state) => state.auction);
  const [show, setShow] = useState(false);
  const [getReadyTime, setGetReadyTime] = useState();
  const [checkTime, setCheckTime] = useState(false);
  const [showTestGame, setShowTestGame] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [dates, setDates] = useState([]);

  const auth = useAuth();

  // Function to calculate the next 10 days
  const calculateNextTenDays = (start) => {
    const result = [];
    const initialDate = new Date(start);
    for (let i = 0; i < 10; i++) {
      const nextDate = new Date(initialDate);
      nextDate.setDate(initialDate.getDate() + i);
      result.push(nextDate.toISOString().split("T")[0]); // Format as YYYY-MM-DD
    }
    setDates(result);
  };

  useEffect(() => {
    if (selectedDate) {
      calculateNextTenDays(selectedDate);
    }
  }, [selectedDate]);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const getTime = () => {
    if (!checkTime) {
      dispatch(getServerTimeT());
    }
  };

  useEffect(() => {
    if (dates.length > 0) {
      handleRequest();
    }
  }, [dates]);

  useEffect(() => {
    if (serverCurrentTime) {
      const getServerDay = serverCurrentTime.split(":");
      const daystring = getServerDay[0].slice(0, 10);

      const checkTime =
        new Date(serverCurrentTime).getTime() >
        new Date(`${daystring}T19:58:00.0+01:00`).getTime();
      // setCheckTime(checkTime);
      setCheckTime(true);
    }
  }, [serverCurrentTime]);

  useEffect(() => {
    if (accesstoken) {
      dispatch(SubscriptionDetailsT());
    }
  }, [accesstoken, auction50]);

  const handlegetReadyShow = () => {
    setShow(true);
  };

  const handlegetReadyClose = () => {
    setShow(false);
  };

  const handleTestGameOpen = () => {
    setShowTestGame(true);
  };

  const handleTestGameClose = () => {
    setShowTestGame(false);
  };

  const handleRequest = () => {
    dates.forEach((i) => {
      const body = {
        date: i,
      };
      dispatch(getActiveBidderT(body));
    });
  };

  const calculateBasicRevenue = (number, amount) => {
    const result = number * amount;
    return formatAmount(result);
  };

  const calculateDaliyRevenue = (date) => {
    const basicTotal =
      Number(activeSubscriber[date][subscriptionPlans[0]?._id]) * 50 +
      Number(activeSubscriber[date][subscriptionPlans[1]?._id]) * 100 +
      Number(activeSubscriber[date][subscriptionPlans[2]?._id]) * 100;

    const OtherTotal =
      Number(activeSubscriber[date][subscriptionPlans[3]?._id]?.mtn) * 2000 +
      Number(activeSubscriber[date][subscriptionPlans[3]?._id]?.flutterwave) *
        2000 +
      Number(activeSubscriber[date][subscriptionPlans[4]?._id]?.mtn) * 5000 +
      Number(activeSubscriber[date][subscriptionPlans[3]?._id]?.flutterwave) *
        5000;

    const total = basicTotal + OtherTotal;
    return total;
  };

  const calculateTotal = () => {
    if (isEmptyObject(activeSubscriber)) return;
    let total = 0;

    for (let i = 0; i < dates.length; i++) {
      if (activeSubscriber[dates[i]]) {
        const daily = Number(calculateDaliyRevenue(dates[i]));
        total = daily + total;
      }
    }

    return formatAmount(total);
  };

  // go over the array for each total
  const getColumnTotal = (sub_id, channel, isBasic) => {
    if (isEmptyObject(activeSubscriber)) return;
    let total = 0;

    if (isBasic) {
      for (let i = 0; i < dates.length; i++) {
        if (activeSubscriber[dates[i]]) {
          total = Number(activeSubscriber[dates[i]][sub_id]) + total;
        }
      }
    } else {
      for (let i = 0; i < dates.length; i++) {
        if (activeSubscriber[dates[i]]) {
          total = Number(activeSubscriber[dates[i]][sub_id]?.[channel]) + total;
        }
      }
    }

    return total;
  };

  const getColumnRevenueTotal = (sub_id, channel, multiplier, isBasic) => {
    if (isEmptyObject(activeSubscriber)) return;
    let total = 0;

    if (isBasic) {
      for (let i = 0; i < dates.length; i++) {
        if (activeSubscriber[dates[i]]) {
          total =
            Number(activeSubscriber[dates[i]][sub_id]) * multiplier + total;
        }
      }
    } else {
      for (let i = 0; i < dates.length; i++) {
        if (activeSubscriber[dates[i]]) {
          total =
            Number(activeSubscriber[dates[i]][sub_id]?.[channel]) * multiplier +
            total;
        }
      }
    }

    return total;
  };

  return (
    <div>
      <GetReadyModal
        show={show}
        getReadyTime={getReadyTime}
        handleClose={handlegetReadyClose}
      />

      <TestGameModal show={showTestGame} handleClose={handleTestGameClose} />

      <div className="">
        <Header auth={auth} />
        <div class="loader">
          <div class="d-flex flex-column flex-root">
            <div class="page d-flex flex-row flex-column-fluid">
              <aside class="page-sidebar aside-dark placeholder-wave">
                <div class="placeholder col-12 h-100 bg-gray"></div>
              </aside>
              <div class="page-content d-flex flex-column flex-row-fluid">
                <div class="content flex-column p-4 pb-0 d-flex justify-content-center align-items-center flex-column-fluid position-relative">
                  <div class="w-100 h-100 position-relative d-flex align-items-center justify-content-center">
                    <i class="anim-spinner fas fa-spinner me-3"></i>
                    <div>
                      <span>Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex flex-column flex-root">
          <div class="content p-4 d-flex mt-5 flex-column-fluid">
            <div class="container-fluid px-0">
              <div class="row">
                <div class="col-12">
                  <div className="my-4 d-flex justify-content-between">
                    <h4 style={{ color: "#fff" }}>Select Time</h4>
                    <input
                      type="date"
                      value={selectedDate}
                      onChange={handleDateChange}
                    />
                  </div>

                  <div class="card">
                    <div class="table-responsive">
                      <table
                        id="datatable"
                        class="table mt-0 table-striped table-card table-nowrap"
                      >
                        <thead class="text-uppercase small text-muted">
                          <tr>
                            <th>Date</th>
                            <th>#50 Sub</th>
                            <th>#100 Sub </th>
                            <th>#200 Sub </th>
                            <th>#2,000 mtn </th>
                            <th>#2,000 ps </th>
                            <th>#2,000 fw </th>
                            <th>#5,000 mtn </th>
                            <th>#5,000 ps </th>
                            <th>#5,000 fw </th>
                            <th>#50 Sub Revenue</th>
                            <th>#100 Sub Revenue </th>
                            <th>#200 Sub Revenue </th>
                            <th>#2,000 mtn Revenue </th>
                            <th>#2,000 ps Revenue </th>
                            <th>#2,000 fw Revenue </th>
                            <th>#5,000 mtn Revenue </th>
                            <th>#5,000 ps Revenue </th>
                            <th>#5,000 fw Revenue </th>
                            <th> Total Daily Revenue </th>
                          </tr>
                        </thead>
                        <tbody>
                          {dates?.map((i) => {
                            return (
                              <tr>
                                <td>{i}</td>
                                <td>
                                  {" "}
                                  {activeSubscriber[i] &&
                                    activeSubscriber[i][
                                      subscriptionPlans[0]?._id
                                    ]}
                                </td>
                                <td>
                                  {activeSubscriber[i] &&
                                    activeSubscriber[i][
                                      subscriptionPlans[1]?._id
                                    ]}
                                </td>
                                <td>
                                  {activeSubscriber[i] &&
                                    activeSubscriber[i][
                                      subscriptionPlans[2]?._id
                                    ]}
                                </td>
                                <td>
                                  {activeSubscriber[i] &&
                                    activeSubscriber[i][
                                      subscriptionPlans[3]?._id
                                    ]?.mtn}
                                </td>
                                <td>
                                  {activeSubscriber[i] &&
                                    activeSubscriber[i][
                                      subscriptionPlans[3]?._id
                                    ]?.paystack}
                                </td>
                                <td>
                                  {activeSubscriber[i] &&
                                    activeSubscriber[i][
                                      subscriptionPlans[3]?._id
                                    ]?.flutterwave}
                                </td>
                                <td>
                                  {activeSubscriber[i] &&
                                    activeSubscriber[i][
                                      subscriptionPlans[4]?._id
                                    ]?.mtn}
                                </td>
                                <td>
                                  {activeSubscriber[i] &&
                                    activeSubscriber[i][
                                      subscriptionPlans[3]?._id
                                    ]?.paystack}
                                </td>
                                <td>
                                  {activeSubscriber[i] &&
                                    activeSubscriber[i][
                                      subscriptionPlans[3]?._id
                                    ]?.flutterwave}
                                </td>
                                <td>
                                  {" "}
                                  {activeSubscriber[i] &&
                                    calculateBasicRevenue(
                                      Number(
                                        activeSubscriber[i][
                                          subscriptionPlans[0]?._id
                                        ]
                                      ),
                                      50
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {activeSubscriber[i] &&
                                    calculateBasicRevenue(
                                      Number(
                                        activeSubscriber[i][
                                          subscriptionPlans[1]?._id
                                        ]
                                      ),
                                      100
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {activeSubscriber[i] &&
                                    calculateBasicRevenue(
                                      Number(
                                        activeSubscriber[i][
                                          subscriptionPlans[2]?._id
                                        ]
                                      ),
                                      200
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {activeSubscriber[i] &&
                                    calculateBasicRevenue(
                                      Number(
                                        activeSubscriber[i] &&
                                          activeSubscriber[i][
                                            subscriptionPlans[3]?._id
                                          ]?.mtn
                                      ),
                                      2000
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {activeSubscriber[i] &&
                                    calculateBasicRevenue(
                                      Number(
                                        activeSubscriber[i] &&
                                          activeSubscriber[i][
                                            subscriptionPlans[3]?._id
                                          ]?.paystack
                                      ),
                                      2000
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {activeSubscriber[i] &&
                                    calculateBasicRevenue(
                                      Number(
                                        activeSubscriber[i] &&
                                          activeSubscriber[i][
                                            subscriptionPlans[3]?._id
                                          ]?.flutterwave
                                      ),
                                      2000
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {activeSubscriber[i] &&
                                    calculateBasicRevenue(
                                      Number(
                                        activeSubscriber[i] &&
                                          activeSubscriber[i][
                                            subscriptionPlans[4]?._id
                                          ]?.mtn
                                      ),
                                      5000
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {activeSubscriber[i] &&
                                    calculateBasicRevenue(
                                      Number(
                                        activeSubscriber[i] &&
                                          activeSubscriber[i][
                                            subscriptionPlans[4]?._id
                                          ]?.paystack
                                      ),
                                      5000
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {activeSubscriber[i] &&
                                    calculateBasicRevenue(
                                      Number(
                                        activeSubscriber[i] &&
                                          activeSubscriber[i][
                                            subscriptionPlans[4]?._id
                                          ]?.flutterwave
                                      ),
                                      5000
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {activeSubscriber[i] &&
                                    formatAmount(calculateDaliyRevenue(i))}{" "}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tbody>
                          <tr>
                            <td></td>
                            <td>
                              {" "}
                              {subscriptionPlans.length > 0 &&
                                getColumnTotal(
                                  subscriptionPlans[0]?._id,
                                  "mtn",
                                  true
                                )}
                            </td>
                            <td>
                              {subscriptionPlans.length > 0 &&
                                getColumnTotal(
                                  subscriptionPlans[1]?._id,
                                  "mtn",
                                  true
                                )}
                            </td>
                            <td>
                              {subscriptionPlans.length > 0 &&
                                getColumnTotal(
                                  subscriptionPlans[2]?._id,
                                  "mtn",
                                  true
                                )}
                            </td>
                            <td>
                              {subscriptionPlans.length > 0 &&
                                getColumnTotal(
                                  subscriptionPlans[3]?._id,
                                  "mtn",
                                  false
                                )}
                            </td>
                            <td>
                              {subscriptionPlans.length > 0 &&
                                getColumnTotal(
                                  subscriptionPlans[3]?._id,
                                  "paystack",
                                  false
                                )}
                            </td>
                            <td>
                              {subscriptionPlans.length > 0 &&
                                getColumnTotal(
                                  subscriptionPlans[3]?._id,
                                  "flutterwave",
                                  false
                                )}
                            </td>
                            <td>
                              {subscriptionPlans.length > 0 &&
                                getColumnTotal(
                                  subscriptionPlans[4]?._id,
                                  "mtn",
                                  false
                                )}
                            </td>
                            <td>
                              {subscriptionPlans.length > 0 &&
                                getColumnTotal(
                                  subscriptionPlans[4]?._id,
                                  "paystack",
                                  false
                                )}
                            </td>
                            <td>
                              {subscriptionPlans.length > 0 &&
                                getColumnTotal(
                                  subscriptionPlans[4]?._id,
                                  "flutterwave",
                                  false
                                )}
                            </td>
                            <td>
                              {" "}
                              {subscriptionPlans.length > 0 &&
                                getColumnRevenueTotal(
                                  subscriptionPlans[0]?._id,
                                  "mtn",
                                  50,
                                  true
                                )}
                            </td>
                            <td>
                              {" "}
                              {subscriptionPlans.length > 0 &&
                                getColumnRevenueTotal(
                                  subscriptionPlans[1]?._id,
                                  "mtn",
                                  100,
                                  true
                                )}
                            </td>
                            <td>
                              {" "}
                              {subscriptionPlans.length > 0 &&
                                getColumnRevenueTotal(
                                  subscriptionPlans[2]?._id,
                                  "mtn",
                                  200,
                                  true
                                )}
                            </td>
                            <td>
                              {subscriptionPlans.length > 0 &&
                                getColumnRevenueTotal(
                                  subscriptionPlans[3]?._id,
                                  "mtn",
                                  2000,
                                  false
                                )}
                            </td>
                            <td>
                              {subscriptionPlans.length > 0 &&
                                getColumnRevenueTotal(
                                  subscriptionPlans[3]?._id,
                                  "paystack",
                                  2000,
                                  false
                                )}
                            </td>
                            <td>
                              {subscriptionPlans.length > 0 &&
                                getColumnRevenueTotal(
                                  subscriptionPlans[3]?._id,
                                  "flutterwave",
                                  2000,
                                  false
                                )}
                            </td>
                            <td>
                              {subscriptionPlans.length > 0 &&
                                getColumnRevenueTotal(
                                  subscriptionPlans[4]?._id,
                                  "mtn",
                                  5000,
                                  false
                                )}
                            </td>
                            <td>
                              {subscriptionPlans.length > 0 &&
                                getColumnRevenueTotal(
                                  subscriptionPlans[4]?._id,
                                  "paystack",
                                  5000,
                                  false
                                )}
                            </td>
                            <td>
                              {subscriptionPlans.length > 0 &&
                                getColumnRevenueTotal(
                                  subscriptionPlans[4]?._id,
                                  "flutterwave",
                                  5000,
                                  false
                                )}
                            </td>
                            <td>{calculateTotal()}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default ActiveSubscriber;
