import React, { useEffect, useState } from "react";

import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import {
  createCollectionT,
  createFileCollectionT,
  getAuctionT,
  getServerTimeT,
  getlastAuctionT,
  resetCreateCollectionSuccess,
} from "../../redux/slice/auction";
import { useDispatch, useSelector } from "react-redux";

import { useAuth } from "../../hooks/auth";

import { Bars, Circles } from "react-loader-spinner";
import {
  SubscriptionDetailsT,
  getSubscriptionsPlanT,
} from "../../redux/slice/user";
import { Link, useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Select from "react-select";
import { toaster } from "../../notification/toaster";
import {
  collectionSchema,
  SignupSchema,
} from "../../util/validation/validation";

function Collection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // modal state
  const { createCollectionSuccess, collectionLoading } = useSelector(
    (state) => state.auction
  );
  const { subscriptionPlans } = useSelector((state) => state.user);
  const {} = useSelector((state) => state.user);
  const [show, setShow] = useState(false);

  const [checkTime, setCheckTime] = useState(false);
  const [collectionType, setCollectionType] = useState(null);
  const [showTestGame, setShowTestGame] = useState(false);
  const [file, setFile] = useState(null);

  const auth = useAuth();

  const options = [
    { value: "phonenumber", label: "phonenumber" },
    { value: "file", label: "file" },
  ];

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const initialValues = {
    name: "",
    collection: "",
  };

  useEffect(() => {
    if (createCollectionSuccess) {
      navigate("/sms");
    }
    dispatch(resetCreateCollectionSuccess());
  }, [createCollectionSuccess]);

  const submitForm = (values) => {
    if (!collectionType) {
      toaster("error", "Kindly select collection type");
      return;
    }

    if (collectionType.value === "file") {
      if (!file) {
        toaster("error", "Select .csv file");
        return;
      }
      const data = new FormData();
      data.append("file", file); // Append the CSV file
      data.append("name", values?.name);
      data.append("collection_Type", collectionType?.value); // Append other form data

      // const data = {
      //   name: values?.name,
      //   collectionType: collectionType?.value,
      //   collection: collectionArray,
      // };

      dispatch(createFileCollectionT(data));
    }

    if (collectionType.value === "phonenumber") {
      const collectionArray = values.collection
        .split(",")
        .map((hobby) => hobby.trim());

      const data = {
        name: values?.name,
        collection_Type: collectionType?.value,
        collection: collectionArray,
      };
      dispatch(createCollectionT(data));
    }
  };

  return (
    <div>
      <div className="">
        <Header auth={auth} />
        <section style={{ background: "#fff" }} class="za-top-up">
          <div class="container">
            <div class="row justify-content-md-center">
              <div class="col-sm-4 col-md-9">
                <h4 class="main-title my-4"> Create Collection </h4>

                <Formik
                  initialValues={initialValues}
                  validationSchema={collectionSchema}
                  onSubmit={submitForm}
                >
                  {(formik) => {
                    const { isValid, dirty } = formik;

                    return (
                      <Form>
                        <div className="mb-3">
                          <label> Enter Collection </label>
                          <div className="input-group">
                            <Field
                              name="name"
                              id="name"
                              type="text"
                              className="form-control"
                              placeholder="Enter Collection Name"
                            />
                          </div>
                          <ErrorMessage
                            name="name"
                            component="span"
                            className="error"
                          />
                        </div>

                        <div className="mb-3">
                          <label> Collection type </label>
                          <Select
                            defaultValue={collectionType}
                            onChange={setCollectionType}
                            // className="form-control"
                            options={options}
                          />
                        </div>

                        {collectionType?.value === "phonenumber" && (
                          <>
                            <div className="mb-3">
                              <div>
                                <label> Content </label>
                              </div>

                              <div className="input-group">
                                <Field
                                  as="textarea"
                                  id="collection"
                                  name="collection"
                                  className="form-control"
                                  rows="4"
                                  cols="50"
                                  placeholder="phonenumbers should start with 234 and separated with comma e.g 23481******, 234790******"
                                />
                              </div>
                              <ErrorMessage
                                name="collection"
                                component="span"
                                className="error"
                              />
                            </div>

                            <div className="mb-5">
                              <p className="small">
                                NOTE: phonenumbers should start with 234 and
                                separated with comma (,)
                              </p>
                            </div>
                          </>
                        )}
                        {/*         
                        <div className="mb-5">
                          <p
                            style={{ color: "#cc2229" }}
                            className="small text-center"
                          >
                            OR
                          </p>
                        </div> */}
                        {collectionType?.value === "file" && (
                          <div className="mb-5">
                            <div className="mb-2">
                              <label> Content: </label>
                            </div>

                            <input
                              type="file"
                              accept=".csv"
                              onChange={handleFileChange}
                            />
                          </div>
                        )}

                        <div class="d-grid mb-3">
                          <button
                            class="n-btn btn-primary btn-lg kab-bg-midred border-0 fw-bold"
                            type="submit"
                            disabled={collectionLoading}
                          >
                            {collectionLoading ? (
                              <div className="d-flex justify-content-center">
                                <Bars
                                  height="30"
                                  width="30"
                                  color="#FFF"
                                  ariaLabel="bars-loading"
                                  wrapperclassName=""
                                  visible={true}
                                />
                              </div>
                            ) : (
                              "Create Collection"
                            )}
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Collection;
