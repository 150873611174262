import React from "react";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";

const Faq = () => {
  return (
    <>
      <Header />
      <div class="abx-fixed-top">
        <section class="inner-hero text-center">
          <div class="container">
            <div class="row">
              <div class="col-xl-9 mx-auto">
                <h1 class="mb-5">Frequently Asked Questions</h1>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section class="inner-body bg-white faq">
        <div class="container">
          <div class="row  justify-content-md-center">
            <div class="col-md-8">
              <h4>What is Zanobid all about?</h4>
              <h5>
                Zanobid is a unique online bidding platform that allows you to
                BID on brand new products like cars, iPhones, laptops, washing
                machines, etc, and gives you a chance to WIN the products with
                a one-time daily subscription of ₦50, ₦100 or ₦200 (MTN Airtime)
              </h5>
              <br />
              <h4>How does Zanobid work?</h4>
              <h5>
                Zanobid subscribed users compete with each other to win items.
                Once you've subscribed for ₦50, ₦100 or ₦200 (MTN Airtime), you'll be able to bid on any
                live auction on the contegory you subscribed for. The person who clicks on Bid "BID NOW" the fastest
                and most consistently before the auction ends at 0:00:00 wins.
              </h5>
              <br />
              <h4>I just won a product. What will I do next?</h4>
              <h5>
                One of our representatives will contact you with further
                instructions.
              </h5>
              <br />
              <h4>What are the payment options for subscribing to Zanobid?</h4>
              <h5>
                All approved payment options are available on Zanobid.com. DO
                NOT PAY MONEY INTO ANYONES PERSONAL ACCOUNT.
              </h5>
              <br />
              <h4>Are Zanobid employees allowed to place bids?</h4>
              <h5>
                No! Employees, developers, and anyone else involved directly or
                indirectly in Zanobid's business process are strictly prohibited
                from bidding on the products.
              </h5>
              <br />
              <h4>Who can participate in Zanobid?</h4>
              <h5>
                Everyone who is at least 18 years old can register and take part
                in Zanobid bids.
              </h5>
              <br />
              <h4>What is prohibited while bidding at Zanobid?</h4>
              <h5>
                Using third-party bidding software, tools, or scripts to place
                automatic bids or operate your account in this manner is
                strictly prohibited and will result in a violation of our terms
                and conditions.
              </h5>
              <br />
              <h4>
                Is it legal to offer such online bidding services and to
                participate in them?
              </h4>
              <h5>
                Yes, without a doubt. This is legal in Nigeria, and we must meet
                the compliance requirements of the CBN, the National Lottery
                Regulatory Commission, various banks, and payment gateway
                service providers.
              </h5>
              <br />
              <h4>How long does a Zanobid bid last?</h4>
              <h5>
                Every day at 8:00 pm, bids on Zanobid are live. There is no set
                deadline for bidding. The bidding is over when the bid timer
                runs out.
              </h5>
              <br />
              <h4>What type of products does Zanobid put up here for bids?</h4>
              <h5>
                Smart phones, regular phones, and mobile accessories; laptops,
                notebooks, tablets, desktops, iPods, music systems; LED or LCD
                TVs; home appliances; and everything else. Whatever the latest
                trends in Nigeria and the most wanted by youngsters
              </h5>
              <br />
              <h4>
                Are all products brand new, original, and accompanied by the
                original bill and warranty?
              </h4>
              <h5>
                Absolutely yes! All products on Zanobid are 100% credible,
                original, and come with a manufacturer's warranty.
              </h5>
              <br />
              <h4>
                Is there any winning limit? Can I participate and win unlimited
                products?
              </h4>
              <h5>
                There are no limits to Zanobid. You can participate in unlimited
                bids and also win an unlimited number of products. There are no
                such limitations.
              </h5>
              <br />
              <h4>
                How long is my subscription fee valid for after subscribing? Is
                my subscription fee refundable?
              </h4>
              <h5>
                Your ₦50, ₦100 or ₦200 (MTN Airtime) subscription expires after 24 hours (1 days). Your
                subscription allows you to bid on any live product in the category you subscribed to, but it is
                not refundable once paid.
              </h5>
              <br />
              <h4>Where is the Zanobid office located?</h4>
              <h5>
                We don’t have a physical store or office since we are not a
                shop. Zanobid is an online bidding platform, or, simply put, an
                online version of your local bidding platforms.
              </h5>
              <br />
              <h4>Can I open more than one account on Zanobid?</h4>
              <h5>
                Not at all. It is illegal for a single user to operate multiple
                accounts. Our system monitors your IP address and MAC address.
                If you are found violating this rule, we will terminate your
                account permanently, and your subscription will be void.
              </h5>
              <br />
              <h4>
                My question is not listed here and I need further clarification
              </h4>
              <h5>
                We are open-minded. Just contact us by reaching out to us on any
                of our social accounts. We will get back to you soon with
                clarifications.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Faq;
