import React, { useEffect, useState } from "react";

import {
  getActiveBidderT,
  getAuctionT,
  getServerTimeT,
  getlastAuctionT,
  getstatsT,
} from "../../redux/slice/auction";
import { useDispatch, useSelector } from "react-redux";

import { useAuth } from "../../hooks/auth";

import GetReadyModal from "../../components/modal/getReadyModal";
import { Circles } from "react-loader-spinner";
import {
  SubscriptionDetailsT,
  downloadReportT,
  getSubscriptionsPlanT,
} from "../../redux/slice/user";
import { useNavigate } from "react-router-dom";

import Header from "../../components/header/header";
import Select from "react-select";

import { formatAmount, isEmptyObject } from "../../util/helper";

function Insight() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // modal state
  const {
    auction50,

    serverCurrentTime,
  } = useSelector((state) => state.auction);
  const { subscriptionPlans } = useSelector((state) => state.user);
  const { accesstoken, hasPaidOnce } = useSelector((state) => state.user);
  const { stats } = useSelector((state) => state.auction);
  const [show, setShow] = useState(false);

  const [checkTime, setCheckTime] = useState(false);
  const [showTestGame, setShowTestGame] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [date, setDate] = useState();
  const [dateOption, setDateOption] = useState([]);

  const auth = useAuth();

  useEffect(() => {
    if (stats.length > 0) {
      const array = [];
      stats.map((values) => {
        array.push({ label: values.date, value: values.file_name });
      });
      setDateOption(array);
    }
  }, [stats]);

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  useEffect(() => {
    if (date) {
      dispatch(downloadReportT(date.value));
    }
  }, [date]);

  useEffect(() => {
    dispatch(getstatsT());
  }, []);

  const handleDate = (dateString) => {
    // Convert the string to a Date object
    const date = new Date(dateString);

    // Format the date using toLocaleString
    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // Use 24-hour format
    });

    return formattedDate;
  };

  return (
    <div>
      <div className="">
        <Header auth={auth} />
        <div class="loader">
          <div class="d-flex flex-column flex-root">
            <div class="page d-flex flex-row flex-column-fluid">
              <aside class="page-sidebar aside-dark placeholder-wave">
                <div class="placeholder col-12 h-100 bg-gray"></div>
              </aside>
              <div class="page-content d-flex flex-column flex-row-fluid">
                <div class="content flex-column p-4 pb-0 d-flex justify-content-center align-items-center flex-column-fluid position-relative">
                  <div class="w-100 h-100 position-relative d-flex align-items-center justify-content-center">
                    <i class="anim-spinner fas fa-spinner me-3"></i>
                    <div>
                      <span>Loading...</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex flex-column flex-root">
          <div class="content p-4 d-flex mt-5 flex-column-fluid">
            <div class="container-fluid px-0">
              <h4 className="text-center" style={{ color: "#fff" }}>
                {" "}
                SMS Delivery Report{" "}
              </h4>
              {/* <button onClick={handleDownload}>download</button> */}
              <div class="row">
                <div class="col-12">
                  <div className="my-4 d-flex justify-content-between">
                    <h5 style={{ color: "#fff" }}>Select Day</h5>
                    <Select
                      defaultValue={date}
                      onChange={setDate}
                      className="w-25"
                      options={dateOption}
                    />
                  </div>

                  {/* <div class="card">
                    <div class="table-responsive">
                      {date && Object.keys(date?.value).length > 0 && (
                        <table
                          id="datatable"
                          class="table mt-0 table-striped table-card table-nowrap"
                        >
                          <thead class="text-uppercase small text-muted">
                            <tr>
                              <th>PhoneNumber</th>
                              <th> Message Status </th>
                              <th> Delivered Data </th>
                            </tr>
                          </thead>
                          <tbody>
                            {date &&
                              date.value?.map((value) => {
                                return (
                                  <tr>
                                    <td>{value.receiver}</td>
                                    <td>{value.messagestate}</td>
                                    <td>{handleDate(value.delivered_at)}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Insight;
