import Countdown from "react-countdown";
import { useDispatch } from "react-redux";
import { general, personal } from "../../redux/slice/bid";

const RenderCountdown = ({ time }) => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
    } else {
      // Render a countdown

      return showTime(days, hours, minutes, seconds);
    }
  };

  return <Countdown date={time} renderer={renderer} />;
};

const showTime = (days, hours, minutes, seconds) => {
  if (days > 0) {
    return (
      <b className="d-inline-block">
        {days}d:{hours}h:{minutes}m:{seconds}s
      </b>
    );
  }

  if (days <= 0 && hours > 0) {
    return (
      <b className="d-inline-block">
        {hours}h:{minutes}m:{seconds}s
      </b>
    );
  }

  if (days <= 0 && hours <= 0 && minutes > 0) {
    return (
      <b className="d-inline-block">
        {minutes}m:{seconds}s
      </b>
    );
  }

  if (days <= 0 && hours <= 0 && minutes <= 0 && seconds > 0) {
    return <b className="d-inline-block">{seconds}s</b>;
  }

  if (days === 0) {
    return (
      <b className="d-inline-block">
        {hours}h:{minutes}m:{seconds}s
      </b>
    );
  }

  // if (days === 0 && hours === 0) {
  //   return (
  //     <b className="d-inline-block">
  //       {minutes}m:{seconds}s
  //     </b>
  //   );
  // }

  // if (days === 0 && hours === 0 && minutes === 0) {
  //   return (
  //     <b className="d-inline-block">
  //       {seconds}s
  //     </b>
  //   );
  // }
};

export default RenderCountdown;
