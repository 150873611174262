import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { SignInSchema, SignupSchema } from "../../util/validation/validation";
import { useDispatch, useSelector } from "react-redux";
import { loginT } from "../../redux/slice/user";
import { Bars } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";

function LoginForm({ phonenumber }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const { loginLoading, accesstoken } = useSelector((state) => state.user);

  useEffect(() => {
    if (accesstoken) {
      navigate("/sms");
    }
  }, [accesstoken]);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const submitForm = (values) => {
    const data = {
      email: values?.email,
      password: values?.password,
    };
    dispatch(loginT(data));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignInSchema}
      onSubmit={submitForm}
    >
      {(formik) => {
        const { isValid, dirty } = formik;

        return (
          <Form>
            <h3 className="mb-1 fw-bold">Sign-In</h3>

            <div className="mb-3">
              <label> Email </label>
              <div className="input-group">
                <Field
                  name="email"
                  id="email"
                  type="text"
                  className="form-control"
                  placeholder="Enter Email"
                />
              </div>
              <ErrorMessage name="email" component="span" className="error" />
            </div>

            <div className="mb-3">
              <label>Password</label>
              <div className="input-group">
                <Field
                  name="password"
                  id="password"
                  type="password"
                  className="form-control"
                  placeholder="Enter Password"
                />
              </div>
              <ErrorMessage
                name="password"
                component="span"
                className="error"
              />
            </div>

            <div className="d-grid mb-3">
              <button
                className="n-btn btn-primary btn-lg kab-bg-midred border-0 fw-bold"
                type="submit"
                disabled={!(dirty && isValid)}
              >
                {loginLoading ? (
                  <div className="d-flex justify-content-center">
                    <Bars
                      height="30"
                      width="30"
                      color="#FFF"
                      ariaLabel="bars-loading"
                      wrapperclassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  "Continue"
                )}
              </button>
            </div>
            <div className="mb-4">
              <p className="small">
                By continuing, you agree to Nucleuswave's <br />
              </p>
            </div>

            <hr />
            <div className="mb-3">
              <p>
                Signup With Nucleuswave? <Link to="/signup"> Signup </Link>
              </p>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default LoginForm;
