import logo from "./logo.svg";
import { Routes, Route, useLocation } from "react-router-dom";
import Login from "../src/page/Login/login.jsx";
import Home from "../src/page/home/home.jsx";
import { ToastContainer } from "react-toastify";
import Signup from "../src/page/signup/signup";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { useNetworkStatus } from "./network/useNetworkStatus";
import ViewLast from "./page/view-last-winners/view";
import NotFound from "./page/page-not-found/pageNotFound";
import SignupNext from "./page/signup/Signup-next";
import Payment from "./page/payment/payment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useJwt } from "react-jwt";
import { useEffect } from "react";
import ProtectedApp from "./page/protectedApp/ProtectedApp";
import Terms from "./page/terms/terms";
import About from "./page/about-us/about";
import Contact from "./page/contact-us/contact";
import Faq from "./page/faq/faq";
import HowItWorks from "./page/how-it-works/how-it-works";
import WinningTips from "./page/winning-tips/winning-tips";
import Policy from "./page/policies/policy";
import Subscribe from "./page/subscribe/subscribe";
import { useState } from "react";
import PaymentMethod from "./page/subscribe/paymentMethod.jsx";
import SubscriptionPlan from "./page/subscribe/subscriptionPlan.jsx";
import SignupGateway from "./page/signup/signup-gateway.jsx";
import ForgetPassword from "./page/forget-password/forgetpassword.jsx";
import ForgetPasswordNext from "./page/forget-password/forgetpasswordNext.jsx";
import ForgetPasswordSuccess from "./page/forget-password/forgetPasswordSuccess.jsx";
import ReactGA from "react-ga4";
import ActiveSubscriber from "./page/admin/active-sub/index.js";
import Collection from "./page/collection/index.js";
import Blast from "./page/message/index.js";
import Insight from "./page/insight/index.js";
import MakePayment from "./page/makePayment/make-payment.js";
import SmsSuccess from "./page/message/smsSuccess.jsx";

function App() {
  // const [userTimeZone, setUserTimeZone] = useState('');
  ReactGA.initialize("G-SP9G0WL7MV");
  const location = useLocation;
  useNetworkStatus();
  const navigate = useNavigate();

  const toastConfig = {
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
  };

  // useEffect(() => {
  //   trackPageView(location.pathname);
  // }, []);

  // useEffect(() => {
  //   const getUserTimeZone = () => {
  //     const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  //     setUserTimeZone(userTimeZone);
  //   };

  //   getUserTimeZone();
  // }, []);

  // console.log("timezone", userTimeZone, Intl.DateTimeFormat().resolvedOptions().timeZone)

  // if (Intl.DateTimeFormat().resolvedOptions().timeZone !== "Africa/Lagos" ) {
  //   return <div className="text-center mt-5">
  //     This service is only available to users in Nigeria
  //   </div>
  // }

  // const { accesstoken } = useSelector((state) => state.user);
  // const { isExpired } = useJwt(accesstoken);

  // useEffect(() => {
  //   if (isExpired && accesstoken) {
  //     navigate('/login');
  //   }
  //   console.log('is Expired', isExpired);
  // }, [accesstoken]);

  return (
    <div className="kab-bg-darker">
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="signup" element={<Signup />} />
        <Route path="signup-gateway" element={<SignupGateway />} />
        <Route path="login/:phonenumber" element={<Login />} />
        <Route path="signup/:phonenumber" element={<Signup />} />
        <Route path="terms" element={<Terms />} />
        <Route path="about" element={<About />} />
        <Route path="contact-us" element={<Contact />} />
        <Route path="faq" element={<Faq />} />
        <Route path="how-it-works" element={<HowItWorks />} />
        <Route path="winning-tips" element={<WinningTips />} />
        <Route path="policy" element={<Policy />} />
        <Route path="subscribe" element={<Subscribe />} />
        <Route path="subscription-plan" element={<SubscriptionPlan />} />
        <Route path="payment-method" element={<PaymentMethod />} />
        <Route path="payment" element={<Payment />} />
        <Route path="forget-password" element={<ForgetPassword />} />
        <Route path="forget-password-next" element={<ForgetPasswordNext />} />
        <Route path="sms-success" element={<SmsSuccess />} />

        <Route
          path="forget-password-success"
          element={<ForgetPasswordSuccess />}
        />
        <Route path="*" element={<NotFound />} />
        <Route path="/active-subscribers" element={<ActiveSubscriber />} />
        <Route path="" element={<ProtectedApp />}>
          <Route path="/" element={<Login />} />
          <Route path="last-50" element={<ViewLast />} />
          <Route path="collection" element={<Collection />} />
          <Route path="sms" element={<Blast />} />
          <Route path="insight" element={<Insight />} />
          <Route path="fund-wallet" element={<MakePayment />} />
          {/* <Route path="signup-next" element={<SignupNext />} /> */}
        </Route>
      </Routes>
      <ToastContainer limit={1} {...toastConfig} />
    </div>
  );
}

export default App;
