import React, { useEffect, useState } from "react";

import Header from "../../components/header/header";

import Select from "react-select";
import { getCollectionT, getServerTimeT } from "../../redux/slice/auction";
import { useDispatch, useSelector } from "react-redux";

import { useAuth } from "../../hooks/auth";

import { Bars, Circles } from "react-loader-spinner";

import { Link, useNavigate } from "react-router-dom";

import { ErrorMessage, Field, Form, Formik } from "formik";
import { toaster } from "../../notification/toaster";
import { messageSchema } from "../../util/validation/validation";
import { resetMessageSuccess, sendMessageT } from "../../redux/slice/user";

function Blast() {
  const maxChars = 160;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // modal state
  const { collectionName } = useSelector((state) => state.auction);
  const { messageLoading } = useSelector((state) => state.user);
  const { accesstoken, hasPaidOnce, messageSuccess } = useSelector(
    (state) => state.user
  );
  const [show, setShow] = useState(false);
  const [getReadyTime, setGetReadyTime] = useState();
  const [checkTime, setCheckTime] = useState(false);
  const [showTestGame, setShowTestGame] = useState(false);
  const [selectCollection, setSelectCollection] = useState([]);
  const [collectionNameOption, setCollectionOption] = useState([]);
  const [countLength, SetCountLength] = useState(0);
  const [bracketLength, SetbracketLength] = useState(0);

  const auth = useAuth();

  const getTime = () => {
    if (!checkTime) {
      dispatch(getServerTimeT());
    }
  };

  useEffect(() => {
    dispatch(resetMessageSuccess());
    dispatch(getCollectionT());
  }, []);

  useEffect(() => {
    if (messageSuccess) {
      navigate("/sms-success");
      dispatch(resetMessageSuccess());
    }
  }, [messageSuccess]);

  useEffect(() => {
    if (collectionName.length > 0) {
      const array = [];
      collectionName.map((value, i) => {
        array.push({ label: value.name, value: value.name });
      });

      setCollectionOption(array);
    }
  }, [collectionName]);

  const initialValues = {
    message: "",
  };

  const submitForm = (values) => {
    if (!selectCollection) {
      toaster("error", "Kindly select collection");
      return;
    }

    const data = {
      name: selectCollection?.value,
      message: values.message,
    };
    dispatch(sendMessageT(data));
  };

  return (
    <div>
      <div className="">
        <Header auth={auth} />
        <section style={{ background: "#fff" }} class="za-top-up">
          <div class="container">
            <div class="row justify-content-md-center">
              <div class="col-sm-4 col-md-9">
                <h4 class="main-title my-4"> Send SMS </h4>

                <Formik
                  initialValues={initialValues}
                  validationSchema={messageSchema}
                  onSubmit={submitForm}
                >
                  {(formik) => {
                    const { isValid, dirty, handleChange, handleBlur, values } =
                      formik;

                    return (
                      <Form>
                        <div className="mb-3">
                          <label> Select collection </label>

                          <Select
                            defaultValue={selectCollection}
                            onChange={setSelectCollection}
                            // className="form-control"
                            options={collectionNameOption}
                          />
                        </div>

                        <div className="mb-3">
                          <label> Message </label>
                          <div className="input-group">
                            <Field
                              as="textarea"
                              id="message"
                              maxLength={maxChars + bracketLength}
                              onChange={(e) => {
                                const matches =
                                  e.target.value?.match(/[\[\]]/g) ?? [];

                                SetbracketLength(matches?.length);
                                const countLength =
                                  e.target.value?.length - matches?.length;

                                SetCountLength(countLength);

                                if (countLength <= maxChars) {
                                  handleChange(e);
                                }
                              }}
                              onBlur={handleBlur}
                              value={values.message}
                              name="message"
                              className="form-control"
                              rows="4"
                              cols="50"
                              placeholder="Enter your message here..."
                            />
                          </div>
                          <div>
                            <small>
                              {values.message &&
                                `${countLength}/${maxChars} characters`}
                            </small>
                          </div>

                          <ErrorMessage
                            name="message"
                            component="span"
                            className="error"
                          />
                        </div>

                        <div class="d-grid mb-3">
                          <button
                            class="n-btn btn-primary btn-lg kab-bg-midred border-0 fw-bold"
                            type="submit"
                            disabled={!(dirty && isValid) || messageLoading}
                          >
                            {messageLoading ? (
                              <div className="d-flex justify-content-center">
                                <Bars
                                  height="30"
                                  width="30"
                                  color="#FFF"
                                  ariaLabel="bars-loading"
                                  wrapperclassName=""
                                  visible={true}
                                />
                              </div>
                            ) : (
                              "Send"
                            )}
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Blast;
