import React, { useState } from "react";
import logo from "../../assets/images/icons/nucleuswave.svg";
import logoBlack from "../../assets/images/icons/nucleuswave_black.svg";
import coloredLogo from "../../assets/images/logo-colored.png";
import coloredLogo2 from "../../assets/images/logo-colored-2x.png";
import { useSelector, useDispatch } from "react-redux";
import { reset } from "../../redux/slice/user.js";
import { Link, useNavigate } from "react-router-dom";
import { clearStorage, removeDataInStorage } from "../../util/storage";
import { formatAmount } from "../../util/helper.js";

function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return "th"; // handles 11th to 19th
  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

function Header({ auth }) {
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const {
    accesstoken,
    userName,
    subscriptionExpiryDate,
    hasACurrentValidSubscriptionFor2kAnd5K,
    subscriptionEndingDate2kOr5k,
    activeSubscription,
    balance,
  } = useSelector((state) => state.user);

  const logout = () => {
    dispatch(reset());
    removeDataInStorage("accesstoken");
    clearStorage();
  };

  const formatedExpiryDate = (timestamp) => {
    // Create a new Date object from the timestamp
    const date = new Date(timestamp);

    // Array of month names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Extract the date components
    const day = date.getUTCDate(); // Day
    const dayWithSuffix = day + getOrdinalSuffix(day); // Day with ordinal suffix
    const monthName = monthNames[date.getUTCMonth()]; // Month name
    const year = date.getUTCFullYear(); // Year

    // Format the date as 'Dayth Month Year'
    const formattedDate = `Expires:\u00A0${dayWithSuffix} ${monthName},\u00A0${year}`;
    return formattedDate;
  };

  const buttonText = () => {
    if (hasACurrentValidSubscriptionFor2kAnd5K) {
      return formatedExpiryDate(subscriptionEndingDate2kOr5k);
    } else if (activeSubscription) {
      return formatedExpiryDate(subscriptionExpiryDate);
    } else {
      return "Subscribe Now";
    }
  };

  return (
    <div className="kab-header fixed-top kab-bg-darker">
      <nav className="navbar navbar-light">
        <div className="container-fluid">
          <div className="kab-menu my-3">
            <button
              className="navbar-toggler"
              type="button"
              onClick={() => setShow(true)}
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <a className="navbar-brand" href="/">
              <img style={{ width: "20%" }} src={logo} alt="nucleus" />
            </a>
            <div
              className={
                show
                  ? "offcanvas offcanvas-start show"
                  : "offcanvas offcanvas-start"
              }
              tabIndex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
              style={{ visibility: "visible" }}
            >
              <div className="offcanvas-header">
                <a href="/">
                  <img style={{ width: "30%" }} src={logoBlack} alt="nucleus" />
                </a>
                <button
                  type="button"
                  onClick={() => setShow(false)}
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body p-0">
                <ul className="navbar-nav justify-content-end flex-grow-1">
                  <div className="menu-groups py-1">
                    <li className="nav-item mb-3">
                      Wallet Balance: {formatAmount(balance)}
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        aria-current="page"
                        href="/"
                      >
                        <i className="fa-solid fa-house-chimney-window"></i>Home
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        aria-current="page"
                        href="/collection"
                      >
                        <i class="fa-solid fa-users-viewfinder"></i>
                        Collection
                      </a>
                    </li>{" "}
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        aria-current="page"
                        href="/sms"
                      >
                        <i class="fa-solid fa-message"></i> SMS
                      </a>
                    </li>{" "}
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        aria-current="page"
                        href="/insight"
                      >
                        <i class="fa-solid fa-scroll"></i>
                        Insight
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        aria-current="page"
                        href="/Payment-method"
                      >
                        <i class="fa-solid fa-credit-card"></i>
                        payment
                      </a>
                    </li>
                    {/* <li className="nav-item">
                      <a
                        className="nav-link"
                        aria-current="page"
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#dummyGame"
                      >
                        <i className="fa-solid fa-vial-circle-check"></i>Play a
                        Test Game
                      </a>
                    </li> */}
                  </div>

                  <div className="menu-groups">
                    <li className="nav-item">
                      <Link to="/login" onClick={logout} className="nav-link">
                        <i className="fa-solid fa-right-from-bracket"></i>Logout
                      </Link>
                    </li>
                  </div>
                </ul>
              </div>
            </div>
          </div>

          {/* {userName ? (
            <>
              <Link className="sign-in za-white">
                <span className="">{userName}</span>
                <i className="far fa-user"></i>
              </Link>
            </>
          ) : (
            <>
              <Link to="/login" className="sign-in za-white">
                <span> Sign In ›</span>
                <i className="far fa-user"></i>
              </Link>
            </>
          )} */}
        </div>
      </nav>

      {/* <div className="text-center">
        <div className="container d-grid">
          <button
            onClick={() => {
              navigate("/payment-method");
            }}
            className="btn zano-bg-blue border-0"
            type="button"
          >
            {buttonText()}
          </button>
        </div>
      </div> */}
    </div>
  );
}

export default Header;
