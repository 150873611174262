import React, { useEffect, useState } from "react";
import FooterLogin from "../../components/footer/footer-login";
import FBAICON from "../../assets/images/fba.svg";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import Select from "react-select";
import {
  getAuctionT,
  getCollectionT,
  getServerTimeT,
  getlastAuctionT,
  sendMessageT,
} from "../../redux/slice/auction";
import { useDispatch, useSelector } from "react-redux";

import { useAuth } from "../../hooks/auth";

import { Bars, Circles } from "react-loader-spinner";

import { Link, useNavigate } from "react-router-dom";

import { ErrorMessage, Field, Form, Formik } from "formik";
import { toaster } from "../../notification/toaster";
import { messageSchema, paymentSchema } from "../../util/validation/validation";
import { initiateSubscriptionT, resetPayment } from "../../redux/slice/user";

function MakePayment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // modal state
  const { collectionName, messageLoading } = useSelector(
    (state) => state.auction
  );
  const { subscriptionPlans } = useSelector((state) => state.user);
  const { accesstoken, hasPaidOnce, fundWalletLoading } = useSelector(
    (state) => state.user
  );
  const [show, setShow] = useState(false);
  const [getReadyTime, setGetReadyTime] = useState();
  const [checkTime, setCheckTime] = useState(false);

  const [selectCollection, setSelectCollection] = useState([]);
  const [collectionNameOption, setCollectionOption] = useState([]);
  const { paymentGateway, paymentLink } = useSelector((state) => state.user);

  const auth = useAuth();

  const getTime = () => {
    if (!checkTime) {
      dispatch(getServerTimeT());
    }
  };

  useEffect(() => {
    // dispatch(getCollectionT());
    dispatch(resetPayment());
  }, []);

  useEffect(() => {
    if (collectionName.length > 0) {
      const array = [];
      collectionName.map((value, i) => {
        array.push({ label: value.name, value: value.name });
      });

      setCollectionOption(array);
    }
  }, [collectionName]);

  useEffect(() => {
    if (paymentLink !== "") {
      window.location.href = paymentLink;
    }
  }, [paymentLink]);

  const initialValues = {
    amount: "",
  };

  const submitForm = (values) => {
    const data = {
      amount: values.amount,
      provider: paymentGateway,
    };
    dispatch(initiateSubscriptionT(data));
  };

  return (
    <div>
      <div className="">
        <Header auth={auth} />
        <section style={{ background: "#fff" }} class="za-top-up">
          <div class="container">
            <div class="row justify-content-md-center">
              <div class="col-sm-4 col-md-9">
                <h4 class="main-title my-4"> Fund Wallet </h4>

                <Formik
                  initialValues={initialValues}
                  validationSchema={paymentSchema}
                  onSubmit={submitForm}
                >
                  {(formik) => {
                    const { isValid, dirty } = formik;

                    return (
                      <Form>
                        <div className="mb-3">
                          <label> Amount </label>
                          <div className="input-group">
                            <Field
                              name="amount"
                              id="amount"
                              type="text"
                              className="form-control"
                              placeholder="Enter Amount"
                            />
                          </div>
                          <ErrorMessage
                            name="amount"
                            component="span"
                            className="error"
                          />
                        </div>

                        <div class="d-grid mb-3">
                          <button
                            class="n-btn btn-primary btn-lg kab-bg-midred border-0 fw-bold"
                            type="submit"
                            disabled={!(dirty && isValid)}
                          >
                            {fundWalletLoading ? (
                              <div className="d-flex justify-content-center">
                                <Bars
                                  height="30"
                                  width="30"
                                  color="#FFF"
                                  ariaLabel="bars-loading"
                                  wrapperclassName=""
                                  visible={true}
                                />
                              </div>
                            ) : (
                              "fund"
                            )}
                          </button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default MakePayment;
