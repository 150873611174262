import Modal from "react-bootstrap/Modal";
import React from "react";

function GetReadyModal({ show, handleClose, getReadyTime }) {
  const getTIme = () => {
    const hours = getReadyTime?.getHours(); // get the hour value
    const minutes = getReadyTime?.getMinutes(); // get the minute value

    // convert hours to 12-hour format and determine AM/PM
    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12;

    // concatenate the time values and AM/PM indicator
    const formattedTime =
      formattedHours + ":" + (minutes < 10 ? "0" + minutes : minutes) + ampm;

    return formattedTime;
  };

  const getDate = () => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayOfWeek = daysOfWeek[getReadyTime?.getUTCDay()];

    // Get the day of the month (1-31)
    const dayOfMonth = getReadyTime?.getUTCDate();

    // Format the date into the name of the month
    const monthName = getReadyTime?.toLocaleString("en-US", { month: "long" }); // "March"

    // Get the year (e.g. 2023)
    const year = getReadyTime?.getUTCFullYear();

    const dateString = `${dayOfWeek}, ${monthName}, ${dayOfMonth} ${year}`;

    return dateString;
  };

  return (
    <Modal dialog show={show} onHide={handleClose}>
      <div className="" id="getReady">
        <div className="modal-body">
          <h2 className="fw-bold">
            This particular auction
            <br />
            will start at <span className="highlight">{getTIme()}</span>
            <br />
            {getDate()}
          </h2>
          <br />
          <p>
            <span className="highlight fw-bold">
              3 simple steps on Zanobid:
            </span>
            <br />
            1. Login with your Phone Number.
            <br />
            2. Subscribe for items at ₦50, ₦100 or ₦200 (MTN Airtime)/daily & Get Ready.
            <br />
            3. Start clicking BID NOW when auction is LIVE.
            <br />
            <br />
            <span className="highlight fw-bold">Tips to Win:</span>
            <br />
            * The faster bidder wins on Zanobid.
            <br />
            * Keep Bidding till you WIN.
            <br />* Don't wait for anyone. Auction can close at anytime.
          </p>
          <button
            type="button"
            className="btn amazon-secondary-button"
            onClick={handleClose}
          >
            Go Back to Home
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default GetReadyModal;
