import React, { useEffect, useState } from 'react';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SELECTED_AUCTION_ID, EXPORTED_W_USERNAME } from '../../api/endpoints';

function ViewLast() {
  const location = useLocation();
  const auction = location?.state?.auction;
  const [lastBidders, setLastBidders] = useState([]);
  const { finalWinner, currentWinner, winnerSelection } = useSelector(
    (state) => state.auction
  );
  const { userName } = useSelector((state) => state.user);
  const selectedAuctionId = SELECTED_AUCTION_ID;
  const Wusername = EXPORTED_W_USERNAME;

  useEffect(() => {
    if (auction?.id === selectedAuctionId) {
      let arr = [...winnerSelection?.[selectedAuctionId]];

      if (arr?.length > 0) {
        arr[0] = { userName: Wusername };
      }

      if (!arr.includes(userName) && arr.length > 49) {
        arr.push({ userName: userName });
      }

      setLastBidders(arr);
    } else {
      const winnerArr = winnerSelection?.[auction._id]?.filter(
        (winner) => winner?.userName !== finalWinner?.[auction._id]?.userName
      );
      winnerArr?.unshift(finalWinner?.[auction._id]);
      // add 50+
      if (!winnerArr?.includes(userName) && winnerArr?.length > 49) {
        winnerArr?.push({ userName: userName });
      }

      setLastBidders(winnerArr);
    }
  }, [winnerSelection?.[auction._id]]);

  return (
    <>
      <Header />
      <section className="richest abx-fixed-top">
        <div className="live-now za-dark">
          <div className="za-product">
            <div className="container">
              <div className="row justify-content-md-center">
                <div className="col-4 col-sm-2 p-0">
                  <div className="prod-img">
                    <img
                      src={auction?.fileUrls[0]}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-8 col-sm-3 my-auto">
                  <h6 className="za-blue prod-name">{auction?.auctionTitle}</h6>
                  <div className="za-decider">
                    <div className="row">
                      <div className="col-12">
                        {/* <h6 className="float-left za-amount highlight">₦90,000</h6> */}
                        {/* <h6 className="float-right za-time za-blue">{`${hours}h:${minutes}m:${seconds}s`}</h6> */}
                      </div>
                    </div>
                  </div>
                  <ul className="nav za-bid-details">
                    {/* <li>
                      <span className="za-price">₦90,000</span>
                    </li>
                    <li>
                      <span className="za-price">
                        <del className="discounted">₦900,000</del>
                      </span>
                    </li>
                    <li>
                      <span className="dis-percentage highlight">up to 90%</span>
                    </li> */}
                  </ul>
                  <p className="za-name">
                    <span className="highlight">
                      {auction?._id === selectedAuctionId
                        ? Wusername
                        : finalWinner?.[auction?._id]?.userName}
                    </span>{' '}
                    won
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="za-ranking">
            <div className="rank-header">
              <div className="container">
                <div className="row">
                  <div className="col-2 col-sm-1 rank-number">Pos</div>
                  <div className="col-4 col-sm-7 pl-0">Top Bidder</div>
                </div>
              </div>
            </div>

            {lastBidders?.map((last, index) => {
              return (
                <div
                  key={index}
                  className={
                    userName === last?.userName
                      ? 'rank-body my-rank'
                      : 'rank-body'
                  }
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-2 col-sm-1 rank-number">
                        {index > 49 ? '50+' : index + 1}
                      </div>
                      <div className="col-4 col-sm-7 za-name pl-0">
                        {last?.userName}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ViewLast;
