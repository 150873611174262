import {
  axiosFilePrivate,
  axiosInstance,
  axiosPrivate,
} from "../../../api/axiosInstance";
import {
  GET_AUCTION,
  PLACE_BID,
  JOIN_AUCTION,
  GET_SINGLE_AUCTION,
  GET_CURRENT_WINNER,
  GET_FINAL_WINNER,
  LAST_AUCTION,
  GET_CURRENT_TIME,
  GET_ACTIVE_BIDDER,
  CREATE_COLLECTION,
  SEND_MESSAGE,
  GET_COLLECTION,
  GET_stats,
  GET_STATS,
  DOWNLOAD_REPORT,
} from "../../../api/endpoints";

export const getAuctionService = async (id) => {
  const res = await axiosPrivate.get(`${GET_AUCTION}?subscriptionId=${id}`);

  return res?.data;
};

export const getActiveBidderService = async (body) => {
  const res = await axiosPrivate.post(`${GET_ACTIVE_BIDDER}`, body);

  return res?.data;
};

export const placeBidService = async (body) => {
  const res = await axiosPrivate.post(PLACE_BID, body);

  return res?.data;
};

export const joinAuctionService = async (body) => {
  const res = await axiosPrivate.post(JOIN_AUCTION, body);

  return res?.data;
};

export const getCurrentWinnerService = async (id) => {
  const res = await axiosPrivate.get(`${GET_CURRENT_WINNER}/${id}`);

  return res?.data;
};

export const getFinalWinnerService = async (id) => {
  const res = await axiosPrivate.get(`${GET_FINAL_WINNER}/${id}`);

  return res?.data;
};

export const getLastAuctionService = async () => {
  const res = await axiosPrivate.get(`${LAST_AUCTION}`);

  return res?.data;
};

export const createCollectionService = async (data) => {
  const res = await axiosPrivate.post(`${CREATE_COLLECTION}`, data);

  return res?.data;
};

export const createFileCollectionService = async (data) => {
  const res = await axiosFilePrivate.post(`${CREATE_COLLECTION}`, data);

  return res?.data;
};

export const sendMessageService = async (data) => {
  const res = await axiosPrivate.post(`${SEND_MESSAGE}`, data);

  return res?.data;
};

export const getCollectionService = async () => {
  const res = await axiosPrivate.get(`${GET_COLLECTION}`);

  return res?.data;
};

export const downloadReportService = async (key) => {
  const res = await axiosPrivate.get(`${DOWNLOAD_REPORT}/${key}`, {
    responseType: "blob",
  });

  const blob = new Blob([res.data], { type: "text/csv" });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = `${key}.csv`;
  link.click();

  return "File downloaded successfully";
};

export const getStatsService = async () => {
  const res = await axiosPrivate.get(`${GET_STATS}`);

  return res?.data;
};

export const getSingleAuctionService = async (auctionId) => {
  const res = await axiosPrivate.get(`${GET_SINGLE_AUCTION}/${auctionId}`);

  return res?.data;
};

export const getServerTimeService = async () => {
  const res = await axiosInstance.get(`${GET_CURRENT_TIME}`);

  return res?.data;
};
