import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from '../src/redux/store/store.js';
import { PersistGate } from 'redux-persist/integration/react';
// styles
// import './assets/css/bootstrap.min5.1.3.css';
// Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/mad.css';
import './assets/css/mad-resize.css';
import './assets/css/kabidi.css';
import './assets/css/kabidi-resize.css';
import 'react-phone-number-input/style.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
