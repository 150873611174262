import { createSlice } from '@reduxjs/toolkit';

import { useAppWebSocket } from '../../ws/websocketConn';

const initialState = {
  readyState: 0,
  lastJsonMessage: null,
  auctionStats: null,
  auctionStatus: null,
  userJoin: null,
};

export const websocketSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    setReadyState: (state, action) => {
      state.readyState = action.payload;
    },
    setLastJsonMessage: (state, action) => {
      state.lastJsonMessage = action.payload;
    },
    setAuctionStatus: (state, action) => {
      state.auctionStatus = action.payload;
    },
    setAuctionStats: (state, action) => {
      state.auctionStats = action.payload;
    },
    setUserJoin: (state, action) => {
      state.userJoin = action.payload;
    },
    resetwebsocket: (state) => {
      state.lastJsonMessage = null;
      state.auctionStats = null;
      state.auctionStatus = null;
      state.userJoin = null;
    },
  },
});

export const {
  setReadyState,
  setLastJsonMessage,
  setAuctionStatus,
  setAuctionStats,
  setUserJoin,
  resetwebsocket,
} = websocketSlice.actions;

export default websocketSlice.reducer;
