export const generateRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

export const formatAmount = (amount) => {
  const formattedAmount = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN", // Change this to your desired currency
    minimumFractionDigits: 2,
    // maximumFractionDigits: 0,
  }).format(amount);

  return formattedAmount;
};

export function isEmptyObject(obj) {
  // Check if the object has any own properties
  return Object.keys(obj).length === 0;
}
