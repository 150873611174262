import Footer from "../../components/footer/footer";
import logo from "../../assets/images/logo.png";
import logo2 from "../../assets/images/logo2.png";
import { Link, useNavigate } from "react-router-dom";

function ForgetPassword() {
  const navigate = useNavigate();

  const handleIsMtn = (e) => {
    e.preventDefault();
    const data = { isMtn: true };

    navigate("/forget-password-next", { state: data });
  };

  const handleNotMtn = () => {
    const data = { isMtn: false };
    navigate("/forget-password-next", { state: data });
  };

  return (
    <>
      <section className="section-pd">
        <div className="section-pd text-center">
          <a href="/">
            <img src={logo} srcSet={`${logo2} 2x`} alt="Zanobid" />
          </a>
        </div>
        <div className="">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-4 col-11">
                <div className="za-bg-white p-3 kab-login">
                  <form>
                    <h3 className="mb-3 fw-bold">Recover Username</h3>
                    <a onClick={handleIsMtn} className="pay-card">
                      <div className="row">
                        <div className="col-11 my-auto">
                          <h5>
                            Registered with MTN <span>Only MTN Users</span>
                          </h5>
                          <p>
                            Your username will be sent to your{" "}
                            <span className="underlined"> MTN number</span>
                          </p>
                        </div>
                        <div className="col-1 p-0 my-auto">
                          <svg
                            width="9"
                            height="14"
                            viewBox="0 0 9 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.299072 11.8768L5.3863 6.78953L0.299072 1.72879L1.86233 0.165527L8.48633 6.78953L1.86233 13.4135L0.299072 11.8768Z"
                              fill="#37475A"
                            />
                          </svg>
                        </div>
                      </div>
                    </a>
                    <a className="pay-card">
                      <div onClick={handleNotMtn} className="row">
                        <div className="col-11 my-auto">
                          <h5>Didn't register with MTN</h5>
                          <p>
                            Your username will be sent to your{" "}
                            <span className="underlined"> Phone number</span>
                          </p>
                        </div>
                        <div className="col-1 p-0 my-auto">
                          <svg
                            width="9"
                            height="14"
                            viewBox="0 0 9 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.299072 11.8768L5.3863 6.78953L0.299072 1.72879L1.86233 0.165527L8.48633 6.78953L1.86233 13.4135L0.299072 11.8768Z"
                              fill="#37475A"
                            />
                          </svg>
                        </div>
                      </div>
                    </a>
                    <div className="mb-4">
                      <p className="small">
                        By continuing, you agree to zanobid's <br />
                        <a href="terms.html">Conditions of Use</a> and{" "}
                        <a href="privacy-policy.html">Privacy Notice</a>
                      </p>
                    </div>
                    <hr />
                    <div className="mb-3">
                      <p>
                        New to zanobid?{" "}
                        <Link to="/payment-method">Create account now ›</Link>
                      </p>
                    </div>
                    <div className="mb-3">
                      <p>
                        Remember your details?{" "}
                        <Link to="/login">Login Now ›</Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ForgetPassword;
