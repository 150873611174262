import React, { useEffect } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { useAuth } from "../../hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Flutter } from "../../assets/images/icons/FlutterwaveLogo.svg";
import { ReactComponent as Mtn } from "../../assets/images/icons/MTNLogo.svg";
import { useDispatch, useSelector } from "react-redux";
import { initiateSubscriptionT } from "../../redux/slice/user";
import { toaster } from "../../notification/toaster";

function SubscriptionPlan() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subscriptionPlans, paymentGateway, paymentLink, accesstoken } =
    useSelector((state) => state.user);

  useEffect(() => {
    if (accesstoken === "") {
      navigate("/signup-gateway");
    }
    if (paymentGateway === "") {
      navigate("/payment-method");
    }
  }, []);

  useEffect(() => {
    if (paymentLink !== "") {
      window.location.href = paymentLink;
    }
  }, [paymentLink]);

  const handlePayment = async (subIndex) => {
    toaster("success", "Processing");
    const body = {
      provider: paymentGateway,
      subscriptionPlanId: subscriptionPlans[subIndex]?._id,
    };
    await dispatch(initiateSubscriptionT(body));
  };

  return (
    <div>
      {" "}
      <Header auth={auth} />
      <section style={{ background: "#fff" }} class="za-top-up">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-sm-4">
              <h4 class="main-title">Select Payment Method</h4>
              <p>You can cancel your subscription at anytime.</p>

              <a onClick={() => handlePayment(3)} class="pay-card">
                <div class="row">
                  <div class="col-11 my-auto">
                    <h5>₦2000 (10 Days)</h5>
                  </div>
                  <div class="col-1 p-0 my-auto">
                    <svg
                      width="9"
                      height="14"
                      viewBox="0 0 9 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.299072 11.8768L5.3863 6.78953L0.299072 1.72879L1.86233 0.165527L8.48633 6.78953L1.86233 13.4135L0.299072 11.8768Z"
                        fill="#37475A"
                      />
                    </svg>
                  </div>
                </div>
              </a>
              <a onClick={() => handlePayment(4)} class="pay-card">
                <div class="row">
                  <div class="col-11 my-auto">
                    <h5> ₦5000 (30 Days) </h5>
                  </div>
                  <div class="col-1 p-0 my-auto">
                    <svg
                      width="9"
                      height="14"
                      viewBox="0 0 9 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.299072 11.8768L5.3863 6.78953L0.299072 1.72879L1.86233 0.165527L8.48633 6.78953L1.86233 13.4135L0.299072 11.8768Z"
                        fill="#37475A"
                      />
                    </svg>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />{" "}
    </div>
  );
}

export default SubscriptionPlan;
