export const gamers = [
  "olawale23",
  "akanni79",
  "ifeoma20",
  "rita34",
  "adetayo43",
  "felix86",
  "eunice45",
  "justin23",
  "innocent29",
  "shola23",
  "efe66",
  "adamma",
  "akachukwu41",
  "diana54",
  "dorcas23",
  "jeremiah",
  "chiamaka77",
  "felicia12",
  "ego92",
  "peace34",
  "jessica23",
  "ismaila45",
  "damilare62",
  "paul23",
  "benjamin26",
  "adaugo25",
  "israel23",
  "bosede74",
  "bala45",
  "chinyere60",
  "amarachi98",
  "joel34",
  "agatha27",
  "sadiq23",
  "juliet12",
  "oluwabukola",
  "adaobi34",
  "chinasa22",
  "james23",
  "oluwasegun",
  "chinedu22",
  "beatrice52",
  "kevin23",
  "racheal34",
  "olabisi34",
  "chidumebi70",
  "adewale",
  "anyanwu78",
  "chidubem53",
  "sefiu34",
  "aniobi",
  "ruth34",
  "babajide",
  "lilian23",
  "nonye23",
  "ifeoma23",
  "aisha83",
  "ayinde",
  "pamela23",
  "adewale56",
  "modupe56",
  "ebuka51",
  "amosu",
  "oyinkansola",
  "charles67",
  "caleb50",
  "angela45",
  "kafilat",
  "obinna23",
  "anya15",
  "chisom48",
  "elizabeth",
  "babatunde24",
  "david23",
  "grace24",
  "arinze20",
  "chinenye84",
  "egwuatu",
  "kudirat",
  "anthonia93",
  "gift34",
  "solomon23",
  "oladunni23",
  "ismaila23",
  "henry83",
  "adimchi89",
  "chidiogo28",
  "immanuel93",
  "samuel23",
  "isaac23",
  "dennis30",
  "adedoyin32",
  "gloria13",
  "aminat",
  "adaobi17",
  "dara94",
  "kingsley",
  "promise23",
  "damilola",
  "anabel76",
  "olufunke23",
  "bukola45",
  "chinonso",
  "ekene96",
  "damilola95",
  "eucharia88",
  "helen34",
  "moses23",
  "adindu63",
  "mary12",
  "naomi34",
  "chinemerem90",
  "olusola34",
  "endurance",
  "mubarak23",
  "haruna43",
  "blessing11",
  "loveth23",
  "funke79",
  "bridget99",
  "frank32",
  "bukola31",
  "bright34",
  "clara80",
  "esther34",
  "sharon23",
  "olugbenga",
  "bankole",
  "hannah28",
  "antonia21",
  "habib70",
  "olumide23",
  "chidiebere65",
  "ayo34",
  "joseph23",
  "martins23",
  "linda34",
  "onyinye23",
  "chidera20",
  "aisha23",
  "deborah68",
  "christy18",
  "oluwatobi",
  "priscilla",
  "efe56",
  "isreal78",
  "aminat23",
  "hope34",
  "anayo22",
  "adebowale",
  "gabriel25",
  "kolawole",
  "adesina",
  "lateef23",
  "faith34",
  "michael23",
  "halima15",
  "ifeanyi39",
  "mercy34",
  "angela57",
  "ekene34",
  "rachael23",
  "chuks29",
  "ikenna57",
  "caleb34",
  "chinasa46",
  "destiny20",
  "bassey12",
  "ebenezer",
  "frank23",
  "grace12",
  "john34",
  "favour23",
  "oladapo23",
  "olufunmi23",
  "dickson75",
  "deborah12",
  "eno23",
  "amaka11",
  "olakunle23",
  "george56",
  "akintoye59",
  "haruna45",
  "hannah23",
  "akpan72",
  "joy34",
  "olamide23",
  "lizzy56",
  "musa23",
  "kufre34",
  "daniel34",
  "ben43",
  "oladimeji",
  "frances94",
  "nkechi23",
  "japhet12",
  "akinola",
  "omolara23",
  "sarah23",
  "osita23",
  "olabode23",
  "philip23",
  "joshua12",
  "janet23",
  "chidiogo",
  "jude23",
  "iheoma56",
  "helen61",
  "asaju",
  "esther20",
  "comfort71",
  "oluwaseun",
  "babajide87",
  "nneka23",
  "mariam23",
  "akin91",
  "david61",
  "immaculate",
  "efosa23",
  "caroline19",
  "gideon23",
  "isabella16",
  "omotayo23",
  "godwin23",
  "akinyemi",
  "christian85",
  "adunni12",
  "kikelomo",
  "raymond23",
  "chiamaka",
  "ebele20",
  "luke23",
  "chizoba39",
  "ejike22",
  "hassan23",
  "agatha34",
  "lydia34",
  "nifemi23",
  "jacob12",
  "oyindamola",
  "oluwatoyin",
  "adewumi47",
  "cynthia37",
  "nnenna34",
  "peter23",
  "annabel",
  "daniel14",
  "ogechi23",
  "gabriel23",
  "andrew64",
  "ngozi34",
  "matthew",
  "ebere49",
  "ikechukwu48",
  "evans12",
  "lucy23",
  "faith12",
  "chinedu23",
  "hilda12",
  "margaret",
  "richard23",
  "emmanuel38",
  "gloria34",
  "enitan56",
  "olamide34",
  "akande",
  "osman23",
  "rebecca23",
  "isaac27",
  "isabella",
  "favour40",
  "chizzy4eva",
  "nneoma7",
  "kingsley5",
  "olayinka8",
  "uju4real",
  "ugonna11",
  "emmanuela5",
  "chuka4you",
  "chinaza4",
  "ogechukwu9",
  "chisom10",
  "obiora6",
  "uzor4life",
  "uchenna8",
  "chukwuebuka",
  "ifechukwu",
  "amarachi9",
  "ndidi6",
  "ebere8",
  "chinemerem",
  "ebunoluwa",
  "chibundu",
  "olumide5",
  "chidera8",
  "udochukwu",
  "okechukwu",
  "nnamdi10",
  "chukwuoma",
  "chiebuka",
  "ikechukwu7",
  "chinonso9",
  "chisomaga",
  "chimdindu",
  "ugochi6",
  "obianuju5",
  "onyekachukwu",
  "urenna5",
  "chisomadu",
  "chikwendu",
  "chijindum",
  "emeka4you",
  "oluchi5",
  "chidimma7",
  "chizaram",
  "uzonna",
  "ifunanya8",
  "kachyboy",
  "adaobi88",
  "uzoma10",
  "nneka4life",
  "olamide5",
  "chinonso7",
  "ifeoma9",
  "chika12",
  "ebuka4u",
  "uche4real",
  "chinyere5",
  "oluwaseyi",
  "chibuzor",
  "nnamdi12",
  "ogechi88",
  "amaka10",
  "emeka22",
  "nkechi14",
  "obinna10",
  "blessing9",
  "ijeoma11",
  "chinwe15",
  "chukwuemeka",
  "onyinye8",
  "chiamaka7",
  "ifeanyi13",
  "ugochukwu",
  "chinonso5",
  "amarachi5",
  "uzochukwu",
  "ngozi4",
  "tobechukwu",
  "amadi6",
  "oluchi8",
  "chidinma9",
  "onyeka11",
  "tochukwu",
  "ogechi10",
  "nkemjika",
  "ifeatu",
  "odira10",
  "chigozie",
  "kamsiyochukwu",
  "adaku5",
  "adannamae",
  "abimbolatee",
  "bolajikay",
  "chiomajoyy",
  "chukabams",
  "damipearl",
  "ebunoluwaxo",
  "emekarae",
  "folatobi1",
  "fumnivee",
  "gbemisolajen",
  "hakeemdee",
  "ijeomalee",
  "inieddie",
  "jidemilly",
  "kehindechy",
  "kunledope",
  "ladiflair",
  "lolacruz",
  "midelily",
  "nenejada",
  "ngozikiki",
  "nnekazuri",
  "obiavery",
  "ogejayy",
  "olumidesky",
  "onyinyeeve",
  "opeyemijax",
  "oyinkansolatia",
  "pelumimimi",
  "ronkekc",
  "sadestarz",
  "sadiqluxe",
  "seunfio",
  "taiwojaxx",
  "tayehaze",
  "temitopeamor",
  "topejazz",
  "tundeqwerty",
  "uchedara",
  "ugochijazzy",
  "uzomakxng",
  "victorinasims",
  "walejojo",
  "wasiublaze",
  "yemitash",
  "yetundejaz",
  "yinkatez",
  "zainabcee",
  "adaeli7e",
  "adeolab4",
  "adebayofox",
  "ademolaj5",
  "adetolaluv",
  "adewalegold",
  "agathamay",
  "aishadee3",
  "akachileo",
  "amakaviv",
  "anayodex",
  "ayokush7",
  "azukazee",
  "babajideeko",
  "biodunmax",
  "bukkyphee",
  "chiamakak8",
  "chibuikelex",
  "chidio3",
  "chigozie9",
  "chikamsoeve",
  "chinonsomack",
  "chizobay2k",
  "dapouno",
  "darababe",
  "demiladekey",
  "ebereglee",
  "ebierefyn",
  "edidiongglow",
  "efejazz5",
  "ekenejude",
  "elohorbae",
  "eseade",
  "estherobi",
  "faithbayo",
  "fatimamax",
  "femidapo",
  "funmisade",
  "gideono4",
  "halimaray",
  "ifeomatiti",
  "ikechukwuy2k",
  "iyabobreezy",
  "jibolaace",
  "kachineo",
  "kaludan",
  "kamarazee",
  "kanyinsolavibez",
  "kemikool",
  "khadija4real",
  "kolajazzy",
  "larasola",
  "mabelkoko",
  "maiyegun1",
  "maryambae",
  "mayowadarl",
  "mmesomazoe",
  "morayoxox",
  "munachi5ive",
  "nanyachi",
  "ngozifab",
  "nkechicee",
  "nnekaava",
  "nnennakiki",
  "nosajae",
  "obinnak9",
  "ogechiblu",
  "olaoluwatj",
  "oluwaseun3",
  "omoladelex",
  "onomekween",
  "onyinyechirae",
  "opeyemidope",
  "oreoluwatee",
  "osatojoy",
  "oyinlolagaga",
  "praisejael",
  "rasheedace",
  "sadiatbee",
  "samsonkay",
  "seunayo",
  "simio2",
  "taiyequeen",
  "tegamighty",
  "temidayoy2k",
  "titijoyy",
  "tochukwuluxe",
  "toluluv",
  "tosinsade",
  "tundebabs",
  "uchennak4",
  "ujucoco",
  "uzoamakapam",
  "victoriadara",
  "wuraolakiki",
  "yakubujossy",
  "yinkajay",
  "yomidare",
  "zarateez",
  "abdulmuyi5",
  "abimbolajay",
  "abisolakush",
  "adannakris",
  "adaugomay",
  "adebisijazz",
  "adejokelara",
  "ademidesleek",
  "adenikeeve",
  "adesuanaija",
  "adetutututu",
  "afolabixtian",
  "agneschi",
  "aishamaxi",
  "akannisam",
  "akeemdav",
  "akintoyeboy",
  "alabagee",
  "amadikele",
  "amarachiglow",
  "amosjnr",
  "anietiebliss",
  "anwulifav",
  "arinzejr",
  "ayodeleart",
  "ayokunleace",
  "azeezflex",
  "bamidelesly",
  "bimbobae",
  "boladeroy",
  "bomatoby",
  "bukunmijai",
  "busayolex",
  "chetaayo",
  "chiamakacee",
  "chidimmamax",
  "chigozirimsam",
  "chikaliz",
  "chineduzee",
  "chinonsorae",
  "chisomjoy",
  "chizaramlee",
  "christyviv",
  "damolakore",
  "danjumajumz",
  "dapolexi",
  "dejijok",
  "doyinmayo",
  "ebukajnr",
  "efeoma",
  "egwuchukwutj",
  "ejikekush",
  "ekeomazoe",
  "elvinabee",
  "emekajay",
  "emmanuelkris",
  "enduranceace",
  "eniolak4",
  "enitanmay",
  "eromoselelee",
  "euniceglow",
  "ezekj",
  "favourjazz",
  "femipius",
  "folakebabs",
  "funmilayotiti",
  "gabrielzara",
  "giftmax",
  "gloriadarl",
  "gracekool",
  "hakeemace",
  "halimatzoe",
  "hammeddee",
  "harrisonbee",
  "hauwaluv",
  "ibrahimkey",
  "ifechukwuzee",
  "ifeoluwamighty",
  "ifunanyaj4",
  "ikennak9",
  "ikeoluwadope",
  "immaculatagaga",
  "iquojay",
  "isiomasola",
  "itunusade",
  "iyabosue",
  "izuchukwunwa",
  "jadesolamax",
  "jajanaija",
  "japhethlee",
  "jeremiahjnr",
  "jessicakush",
  "jimohvic",
  "joyceluv",
  "jubriljazz",
  "judemax",
  "julietkiki",
  "kafayatmami",
  "kamalgee",
  "kehindebabs",
  "kelvinjr",
  "kemiglow",
  "kennyace",
  "kikelomojoy",
  "kingsleyzee",
  "koladekj",
  "kudiratkris",
  "kufremay",
  "kunlejazz",
  "labakelee",
  "lateefahdarl",
  "lizzytoby",
  "lucybae",
  "lulumax",
  "maamezoe",
  "mabelkush",
  "madukajnr",
  "mariamjay",
  "maryamdarl",
  "mayowaace",
  "michaelagee",
  "michellecee",
  "miracleluv",
  "moseszara",
  "moyosue",
  "musapius",
  "nafisatmax",
  "nanaglow",
  "ndukakey",
  "ndidilex",
  "ngozik4",
  "nkechijay",
  "nkirukazee",
  "nnekakush",
  "noahkj",
  "nosaace",
  "nseobonglee",
  "nwaomatoby",
  "obiomazoe",
  "ogechibee",
  "ogochukwuj4",
  "olaoluwaglow",
  "olamideace",
  "olayinkaluv",
  "oluchizee",
  "oluwabukolakj",
  "oluwadarasimijay",
  "oluwadurotimimax",
  "oluwafemikush",
  "oluwafunmilayolex",
  "oluwakemibabs",
  "oluwasegunlee",
  "oluwaseyitutu",
  "oluwatobilobamay",
  "onomesly",
  "onyinyeviv",
  "osagiegee",
  "osasxtian",
  "oviepius",
  "patiencezara",
  "paulkj",
  "peacezoe",
  "peterace",
  "preciousjnr",
  "princekris",
  "princesslex",
  "rachaelluv",
  "rahilakush",
  "ramatbee",
  "rebeccazee",
  "reginamay",
  "richardjazz",
  "ritaglow",
  "rosemaryjay",
  "ruthiemax",
  "sabinajnr",
  "samsonzoe",
  "sarahkj",
  "sefinatlex",
  "sesanbabs",
  "sholamay",
  "similee",
  "solatutu",
  "solomonace",
  "somtochukwusly",
  "stanleyzara",
  "stephaniegee",
  "taiwoj4",
  "tariace",
  "temiladekris",
  "temiloluwajay",
  "temitayomax",
  "temitopezoe",
  "theresaluv",
  "timileyinkj",
  "tinakush",
  "tolulopelee",
  "tomiwatoby",
  "topextian",
  "tundejazz",
  "uchezee",
  "uchennakj",
  "ugochukwuglow",
  "ujuace",
  "umarjay",
  "umohmax",
  "urennalex",
  "uzoamakakris",
  "veralee",
  "victortutu",
  "victoriazara",
  "vincentjnr",
  "viviangee",
  "wendykush",
  "yemibabs",
  "yetundezee",
  "yinusamay",
  "yinkajazz",
  "yvonneluv",
  "zainabkj",
  "zaraace",
  "zaynabmax",
  "zealzoe",
  "zikorajay",
  "zinakush",
  "zainxtian",
  "zoelee",
  "abdulraheemjnr",
  "adannaglow",
  "adedayoace",
  "ademolaluv",
  "adenikezee",
  "adewalekush",
  "adijatjay",
  "adisamay",
  "adunolatutu",
  "agathazara",
  "aishajazz",
  "akannikj",
  "akpangee",
  "akudobee",
  "alabakris",
  "amakalex",
  "aminatlee",
  "aminatoby",
  "amoszoe",
  "anayopius",
  "angelakj",
  "anitaace",
  "anuoluwapojay",
  "anwulimax",
  "aremukush",
  "ariyikeluv",
  "arinzezee",
  "asakebabs",
  "ayozara",
  "ayobamijnr",
  "ayodejiglow",
  "ayokunletutu",
  "azeemxtian",
  "balajazz",
  "basseykj",
  "beautygee",
  "benjaminkris",
  "bernicelee",
  "blessingtoby",
  "boladezoe",
  "bolajijay",
  "bolanlemay",
  "bukolakush",
  "calebzara",
  "catherinej4",
  "celestinakj",
  "chetachiace",
  "chiamakaluv",
  "chidieberezee",
  "chidiogomax",
  "chidubemkush",
  "chiebukalex",
  "chijiokebabs",
  "chikakris",
  "chinasalee",
  "chineduzoe",
  "chinonsojazz",
  "chisomtoby",
  "chizobajay",
  "christianazara",
  "christopherjnr",
  "chukaglow",
  "cindymay",
  "collinstutu",
  "cynthiakj",
  "damilareace",
  "damilolazee",
  "danielkush",
  "danjumajazz",
  "davidmax",
  "dayolex",
  "deborahluv",
  "delekris",
  "demiladezara",
  "destinyjnr",
  "diamondglow",
  "dianetoby",
  "divinezoe",
  "dolapokj",
  "dominicace",
  "dorcaskush",
  "eberejazz",
  "echezonajay",
  "edidiongmax",
  "efelex",
  "ejirogee",
  "ekenekris",
  "ekperetutu",
  "ellazee",
  "emekamay",
  "emmanuelj4",
  "emmanuellakj",
  "enitanzara",
  "eniolajazz",
  "enitankush",
  "enoace",
  "ericlee",
  "estherzoe",
  "euchariajay",
  "eunicetoby",
  "evansglow",
  "faithzee",
  "fathiamax",
  "favourlex",
  "feliciajnr",
  "ferdinandkush",
  "festusjazz",
  "florencezara",
  "francakj",
  "fredrickgee",
  "funkekris",
  "gabrieltutu",
  "giftzee",
  "gloriamay",
  "godwinace",
  "gracezoe",
  "habeeblex",
  "halimajazz",
  "hannahtoby",
  "harunazara",
  "helenkj",
  "henryjay",
  "hildakush",
  "hopejnr",
  "hyacinthglow",
  "ibeabuchizee",
  "ibrahimmax",
  "ifeomaace",
  "ifeoluwalee",
  "ifeoluwakris",
  "ifeyinwajay",
  "ifunanyazoe",
  "ikechukwujazz",
  "ikennatoby",
  "immaculatazara",
  "innocentkj",
  "isaacgee",
  "isabellakush",
  "isaiahzee",
  "isiomamax",
  "israellex",
  "itunuace",
  "iyabolee",
  "jadesolakris",
  "jamesjay",
  "janetzoe",
  "japhettutu",
  "jasminezara",
  "jasonjazz",
  "jennifertoby",
  "jidezee",
  "jimohmax",
  "jocelynlex",
  "johngee",
  "johnsonkush",
  "jonathanzara",
  "josephinekj",
  "joshuaace",
  "joyzoe",
  "judejay",
  "julietmay",
  "justinazee",
  "kafilattoby",
  "kaluglow",
  "kamaruzara",
  "karenkj",
  "kazeemjay",
  "kelechikush",
  "kennethzoe",
  "kevinmax",
  "kikelomoace",
  "kingsleylee",
  "kolajazz",
  "kunletutu",
  "kwamegee",
  "labakezee",
  "lawrencekush",
  "lekanjay",
  "lindazara",
  "linuskj",
  "lisaace",
  "lizzylex",
  "lovethzoe",
  "lucymax",
  "lukmanjazz",
  "lydiatoby",
  "mabelzee",
  "macaulaykris",
  "mahmudjay",
  "maureenzara",
  "maxwellkj",
  "mercyzoe",
  "michealace",
  "miraclelee",
  "modupekush",
  "mosesjay",
  "mojisolazara",
  "moniquejazz",
  "morayotoby",
  "mubarakzee",
  "musamax",
  "nafisatkj",
  "nancyzoe",
  "naomigee",
  "nenezara",
  "nicholaskush",
  "nifemijay",
  "nkirulee",
  "nnekatoby",
  "nnenazee",
  "noahkris",
  "nonyejay",
  "norbertzara",
  "nseglow",
  "ntiedokush",
  "obizoe",
  "obinnamax",
  "obyace",
  "ogelee",
  "oghenetejirijay",
  "ogochukwututu",
  "ojochenemizara",
  "okeziekj",
  "okongee",
  "olabisizee",
  "oladelemax",
  "olajumokejazz",
  "olamidetoby",
  "olaniyikush",
  "olatunjijay",
  "oluchizara",
  "oluwabunmikj",
  "oluwadamilolazoe",
  "oluwafemigee",
  "oluwakemilex",
  "oluwasegunace",
  "oluwaseyilee",
  "oluwatobilobakris",
  "omolarajay",
  "omotayotutu",
  "onyinyezara",
  "onyinyechikj",
  "opeyemizee",
  "opheliakush",
  "osaretinjay",
  "osayandezoe",
  "osemudiamenmax",
  "ositajazz",
  "ossaitoby",
  "osundezara",
  "otoabasikj",
  "oviegee",
  "owolabilex",
  "pamelazoe",
  "patiencemax",
  "pauljazz",
  "peacetoby",
  "perpetuazee",
  "peterkris",
  "philomenajay",
  "preciouszara",
  "princesskj",
  "promisezoe",
  "prospergee",
  "rachelkush",
  "rahmatjay",
  "ramonzara",
  "raphaellee",
  "rebeccatutu",
  "reginazoe",
  "remilekunkj",
  "richardmax",
  "ritajazz",
  "roberttoby",
  "rose",
];

export const testName = [
  "Yakubu",
  "Adamu",
  "Ife",
  "Ademola",
  "Olamide",
  "Amaka",
  "Chidiebere",
];

export const testNameWinner = [
  "Yakubu",
  "Adamu",
  "Ife",
  "Ademola",
  "Olamide",
  "Amaka",
  "Chidiebere",
  "you",
];
