import Footer from "../../components/footer/footer";
import logo from "../../assets/images/logo.png";
import logo2 from "../../assets/images/logo2.png";
import { Link, useNavigate } from "react-router-dom";

function ForgetPasswordSuccess() {
  const navigate = useNavigate();

  const handleIsMtn = (e) => {
    e.preventDefault();
    const data = { isMtn: true };

    navigate("/forget-password-next", { state: data });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    navigate("/login");
  };

  return (
    <>
      <section class="section-pd">
        <div class="section-pd text-center">
          <a href="/">
            {" "}
            <img src={logo} srcSet={`${logo2} 2x`} alt="Zanobid" />
          </a>
        </div>
        <div class="">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-sm-4 col-11">
                <div class="za-bg-white p-3 kab-login">
                  <form class="text-center">
                    <svg
                      width="120px"
                      height="120px"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        width="48"
                        height="48"
                        fill="white"
                        fill-opacity="0.01"
                      />
                      <path
                        d="M24 4L29.2533 7.83204L35.7557 7.81966L37.7533 14.0077L43.0211 17.8197L41 24L43.0211 30.1803L37.7533 33.9923L35.7557 40.1803L29.2533 40.168L24 44L18.7467 40.168L12.2443 40.1803L10.2467 33.9923L4.97887 30.1803L7 24L4.97887 17.8197L10.2467 14.0077L12.2443 7.81966L18.7467 7.83204L24 4Z"
                        fill="#2F88FF"
                        stroke="#000000"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17 24L22 29L32 19"
                        stroke="white"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <h3 class="mb-1 fw-bold">Success!</h3>
                    <div class="mb-3">
                      <p>
                        Your username has been successfully recovered and sent
                        to your phone number.
                      </p>
                    </div>
                    <div class="d-grid mb-3">
                      <button
                        onClick={handleLogin}
                        class="btn btn-primary btn-lg kab-bg-midred border-0 fw-bold"
                      >
                        Login Now
                      </button>
                    </div>
                    <div class="mb-4">
                      <p class="small">
                        By continuing, you agree to zanobid's <br />
                        <Link to="/terms">Conditions of Use</Link> and{" "}
                        <Link to="/terms">Privacy Notice</Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ForgetPasswordSuccess;
