import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPastAuctionWinnerT } from "../../redux/slice/auction";
import { useNavigate } from "react-router-dom";

function AuctionWinnerSection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pastAuctionWinner } = useSelector((state) => state.auction);

  useEffect(() => {
    dispatch(getPastAuctionWinnerT());
  }, []);

  function maskPhoneNumber(phoneNumber) {
    const countryCode = phoneNumber.substring(0, 6); // Assuming the country code is always +234 and the following digits are the area code
    const lastDigits = phoneNumber.substring(10); // Assuming the phone number has 10 digits excluding the country code and area code
    const maskedPhoneNumber = `${countryCode}*****${lastDigits}`;

    return maskedPhoneNumber;
  }

  return (
    <div class="winners section-pd kab-bg-darkest">
      <div class="container">
        <div class="kab-tile-header mb-3">
          <h1 class="fw-bold m-0 za-white">Winners</h1>
          <button
            onClick={() => {
              navigate("/payment-method");
            }}
            class="btn btn-primary amazon-secondary-button border-0"
            type="button"
          >
            Access All Now
          </button>
        </div>
        <div class="row">
          {pastAuctionWinner?.map((auction) => {
            return (
              <div class="col-6 col-sm-2">
                <div class="za-product kab-product kab-bg-darker za-white">
                  <h6 class="prod-name py-2">{auction?.auctionTitle}</h6>
                  <div class="prod-img">
                    <img src={auction?.auctionImages} />{" "}
                  </div>
                  <p class="za-name kab-winner pb-0">
                    <span class="highlight">@{auction?.userName}</span>
                  </p>
                  <h6 class="za-phone pt-0 m-0 pb-2">
                    {maskPhoneNumber(auction?.phoneNumber)}
                  </h6>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default AuctionWinnerSection;
