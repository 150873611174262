import React from 'react';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';

function WinningTips() {
  return (
    <>
      <Header />
      <div class="abx-fixed-top">
        <section class="inner-hero text-center">
          <div class="container">
            <div class="row">
              <div class="col-xl-9 mx-auto">
                <h1 class="mb-5">Winning Tips</h1>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section class="inner-body bg-white faq">
        <div class="container">
          <div class="row  justify-content-md-center">
            <div class="col-md-8">
              <h5>
                <ol>
                  <li>Never allow someone to outbid you.</li>
                  <br />
                  <li>
                    The faster you click on the "BID NOW" button, the greater
                    your chances of winning.
                  </li>{' '}
                  <br />
                  <li>The Auction closes when the time is 00:00:00:00</li>
                  <br />
                  <li>
                    Keep bidding until you win. The bidder who is the fastest
                    and most consistent wins.
                  </li>
                  <br />
                </ol>
              </h5>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default WinningTips;
