import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createCollectionService,
  createFileCollectionService,
  getActiveBidderService,
  getAuctionService,
  getCollectionService,
  getCurrentWinnerService,
  getFinalWinnerService,
  getLastAuctionService,
  getServerTimeService,
  getSingleAuctionService,
  getStatsService,
  joinAuctionService,
  placeBidService,
  sendMessageService,
} from "../services/auctions/auction";
import { toaster } from "../../notification/toaster";
import { getDataInStorage, setDataInStorage } from "../../util/storage";
import { getPastAuctionService } from "../services/raffle/raffle";

const initialState = {
  isLoading: false,
  placeBidLoading: false,
  placeBidData: {},
  closedAuctionStat: {},
  currentWinner: {},
  finalWinner: {},
  winnerSelection: {},
  lastAuction: {},
  pastAuctionWinner: [],
  activeSubscriber: {},
  createCollectionSuccess: false,
  collectionName: [],

  collectionLoading: false,
  stats: [],
  statsLoading: false,
};

const auctionSlice = createSlice({
  name: "auction",
  initialState,
  reducers: {
    resetModalData: (state, action) => {
      state.placeBidData = {};
    },
    resetAuctionSlice: (state) => (state = initialState),
    resetCreateCollectionSuccess: (state) => {
      state.createCollectionSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(singleAuctionT.pending, (state) => {})
      .addCase(singleAuctionT.fulfilled, (state, action) => {
        state.closedAuctionStat = action.payload;
      })
      .addCase(singleAuctionT.rejected, (state, action) => {
        // toaster('error', 'unable to fetch auctions');
      })
      .addCase(getCurrentWinner.pending, (state) => {})
      .addCase(getCurrentWinner.fulfilled, (state, action) => {
        let auctionId = action.payload?.auctionId;
        state.currentWinner = {
          ...state.currentWinner,
          [auctionId]: action.payload?.currentWinner,
        };
        state.winnerSelection = {
          ...state.winnerSelection,
          [auctionId]: action.payload?.winnersPreselection,
        };
      })
      .addCase(getCurrentWinner.rejected, (state, action) => {
        // toaster('error', 'unable to fetch auctions');
      })
      .addCase(getFinalWinner.pending, (state) => {})
      .addCase(getFinalWinner.fulfilled, (state, action) => {
        let auctionId = action.payload?.auctionId;
        if (action.payload.successful === true) {
          state.finalWinner = {
            ...state.finalWinner,
            [auctionId]: action.payload?.finalWinner,
          };
          state.winnerSelection = {
            ...state.winnerSelection,
            [auctionId]: action.payload?.winnersPreselection,
          };
        } else {
          state.finalWinner = {
            ...state.finalWinner,
            [auctionId]: { message: action.payload.message },
          };
        }
      })
      .addCase(getFinalWinner.rejected, (state, action) => {
        // toaster('error', 'unable to fetch auctions');
      })
      .addCase(getlastAuctionT.pending, (state) => {})
      .addCase(getlastAuctionT.fulfilled, (state, action) => {
        state.lastAuction = action.payload;
      })
      .addCase(getlastAuctionT.rejected, (state, action) => {
        // toaster('error', 'unable to fetch auctions');
      })
      .addCase(getPastAuctionWinnerT.pending, (state) => {})
      .addCase(getPastAuctionWinnerT.fulfilled, (state, action) => {
        state.pastAuctionWinner = action.payload;
      })
      .addCase(getPastAuctionWinnerT.rejected, (state, action) => {
        // toaster('error', 'unable to fetch auctions');
      })

      .addCase(getActiveBidderT.pending, (state) => {})
      .addCase(getActiveBidderT.fulfilled, (state, action) => {
        const plans = getDataInStorage("plans");

        state.activeSubscriber[action.payload.date] = action.payload.value;
      })
      .addCase(getActiveBidderT.rejected, (state, action) => {
        // toaster('error', 'unable to fetch auctions');
      })
      .addCase(createCollectionT.pending, (state) => {
        state.collectionLoading = true;
      })
      .addCase(createCollectionT.fulfilled, (state, action) => {
        state.collectionLoading = false;
        state.createCollectionSuccess = true;
        toaster("success", "collection successfully created");
      })
      .addCase(createCollectionT.rejected, (state, action) => {
        state.collectionLoading = false;
        toaster("error", action.payload.message);
      })
      .addCase(createFileCollectionT.pending, (state) => {
        state.collectionLoading = true;
      })
      .addCase(createFileCollectionT.fulfilled, (state, action) => {
        state.collectionLoading = false;
        state.createCollectionSuccess = true;
        toaster("success", "collection successfully created");
      })
      .addCase(createFileCollectionT.rejected, (state, action) => {
        state.collectionLoading = false;
        toaster("error", action.payload.message);
      })
      .addCase(getCollectionT.pending, (state) => {})
      .addCase(getCollectionT.fulfilled, (state, action) => {
        state.collectionName = action.payload;
      })
      .addCase(getCollectionT.rejected, (state, action) => {
        // toaster('error', 'unable to fetch auctions');
      })

      .addCase(getstatsT.pending, (state) => {
        state.statsLoading = true;
      })
      .addCase(getstatsT.fulfilled, (state, action) => {
        state.statsLoading = false;
        state.stats = action.payload;
      })
      .addCase(getstatsT.rejected, (state, action) => {
        state.statsLoading = false;
        // toaster('error', 'unable to fetch auctions');
      });
  },
});

export const getAuctionT = createAsyncThunk(
  "auction/get",
  async (data, thunkAPI) => {
    try {
      const response = await getAuctionService(data);

      return response;
    } catch (error) {
      if (error?.response) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        console.log("Error", error.message);
        toaster("warn", `${error.message} Check your network connection`);
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getActiveBidderT = createAsyncThunk(
  "get/active-bidder",
  async (data, thunkAPI) => {
    try {
      const response = await getActiveBidderService(data);

      return response;
    } catch (error) {
      if (error?.response) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        console.log("Error", error.message);
        toaster("warn", `${error.message} Check your network connection`);
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const singleAuctionT = createAsyncThunk(
  "auction/single/get",
  async (data, thunkAPI) => {
    try {
      const response = await getSingleAuctionService(data);

      return response;
    } catch (error) {
      if (error?.response) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        console.log("Error", error.message);
      }
    }
  }
);

export const placeBidT = createAsyncThunk(
  "auction/place",
  async (data, thunkAPI) => {
    try {
      const response = await placeBidService(data);

      return response;
    } catch (error) {
      if (error?.response) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        console.log("Error", error.message);
      }
    }
  }
);

export const joinAuctionT = createAsyncThunk(
  "auction/join",
  async (data, thunkAPI) => {
    try {
      const response = await joinAuctionService(data);

      return response;
    } catch (error) {
      if (error?.response) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        console.log("Error", error.message);
      }
    }
  }
);

export const getCurrentWinner = createAsyncThunk(
  "auction/current",
  async (data, thunkAPI) => {
    try {
      const response = await getCurrentWinnerService(data);

      return response;
    } catch (error) {
      if (error?.response) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        console.log("Error", error.message);
      }
    }
  }
);

export const getFinalWinner = createAsyncThunk(
  "auction/final",
  async (data, thunkAPI) => {
    try {
      const response = await getFinalWinnerService(data);

      return response;
    } catch (error) {
      if (error?.response) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        console.log("Error", error.message);
      }
    }
  }
);

export const getlastAuctionT = createAsyncThunk(
  "auction/last_auction",
  async (data, thunkAPI) => {
    try {
      const response = await getLastAuctionService(data);

      return response;
    } catch (error) {
      if (error?.response) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        console.log("Error", error.message);
      }
    }
  }
);

export const createCollectionT = createAsyncThunk(
  "create/collection",
  async (data, thunkAPI) => {
    try {
      const response = await createCollectionService(data);

      return response;
    } catch (error) {
      if (error?.response) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        console.log("Error", error.message);
      }
    }
  }
);

export const createFileCollectionT = createAsyncThunk(
  "create/file/collection",
  async (data, thunkAPI) => {
    try {
      const response = await createFileCollectionService(data);

      return response;
    } catch (error) {
      if (error?.response) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        console.log("Error", error.message);
      }
    }
  }
);

export const getCollectionT = createAsyncThunk(
  "get/colletion",
  async (data, thunkAPI) => {
    try {
      const response = await getCollectionService(data);

      return response;
    } catch (error) {
      if (error?.response) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        console.log("Error", error.message);
      }
    }
  }
);

export const getstatsT = createAsyncThunk(
  "get/stats",
  async (data, thunkAPI) => {
    try {
      const response = await getStatsService(data);

      return response;
    } catch (error) {
      if (error?.response) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        console.log("Error", error.message);
      }
    }
  }
);

export const getPastAuctionWinnerT = createAsyncThunk(
  "auction/past/auction_winner",
  async (data, thunkAPI) => {
    try {
      const response = await getPastAuctionService();

      return response;
    } catch (error) {
      if (error?.response) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        console.log("Error", error.message);
      }
    }
  }
);

export const getServerTimeT = createAsyncThunk(
  "auction/server/time",
  async (data, thunkAPI) => {
    try {
      const response = await getServerTimeService();

      return response;
    } catch (error) {
      if (error?.response) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        console.log("Error", error.message);
      }
    }
  }
);

export const {
  resetModalData,
  resetAuctionSlice,
  resetCreateCollectionSuccess,
} = auctionSlice.actions;

export default auctionSlice.reducer;
