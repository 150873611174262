import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAuctionService,
  getCurrentWinnerService,
  getFinalWinnerService,
  getSingleAuctionService,
  joinAuctionService,
  placeBidService,
} from '../services/auctions/auction';
import { toaster } from '../../notification/toaster';
import { setDataInStorage } from '../../util/storage';
import { generateRandomNumber } from '../../util/helper';
import { gamers } from '../../data/gamer';

const initialState = {};

const bidSlice = createSlice({
  name: 'auction',
  initialState,
  reducers: {
    general: (state, action) => {
      const number = generateRandomNumber(0, 1000);
      const bidder = gamers[number];

      return { ...state, [action.payload.auctionId]: { bidder: bidder } };
    },
    personal: (state, action) => {
      return { ...state, [action.payload.auctionId]: { bidder: 'you' } };
    },
  },
});

export const { general, personal } = bidSlice.actions;

export default bidSlice.reducer;
