import { configureStore, combineReducers } from "@reduxjs/toolkit";
import userReducer from "../slice/user.js";
import auctionsReducer from "../slice/auction.js";
import websocketReducer from "../slice/websocketSlice.js";
import bidSlice from "../slice/bid.js";
import raffleSlice from "../slice/raffle.js";
import storage from "redux-persist/lib/storage";
import sessionStorage from "redux-persist/es/storage/session";
import { persistReducer } from "redux-persist";
import persistStore from "redux-persist/es/persistStore";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage: sessionStorage,
};

const rootReducer = combineReducers({
  user: userReducer,
  auction: auctionsReducer,
  websocket: websocketReducer,
  bid: bidSlice,
  raffle: raffleSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
  devTools: process.env.NODE_ENV === "development",
});

export const persistor = persistStore(store);
