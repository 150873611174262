import React from 'react';
import { axiosPrivate } from '../../api/axiosInstance';
import { Outlet, useNavigate } from 'react-router-dom';
import { toaster } from '../../notification/toaster';
import { clearStorage, removeDataInStorage } from '../../util/storage';

const ProtectedApp = () => {
  const navigate = useNavigate();
  axiosPrivate.interceptors.response.use(
    async (response) => response,
    async (error) => {
      // 401: token expires, access token not in Authorization header
      if (error.response?.status === 401) {
        console.log('Session Timeout');
        toaster('error', 'Session Timeout, Kindly log in to continue');
        clearStorage();
        navigate('/login');
      }

      return Promise.reject(error);
    }
  );
  return <Outlet />;
};

export default ProtectedApp;
