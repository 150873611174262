import React from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

function Policy() {
  return (
    <>
      <Header />
      <div class="abx-fixed-top">
        <section class="inner-hero text-center">
          <div class="container">
            <div class="row">
              <div class="col-xl-9 mx-auto">
                <h1 class="mb-5">Privacy Policy</h1>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section class="inner-body bg-white privacy-policy">
        <div class="container">
          <div class="row  justify-content-md-center">
            <div class="col-md-8">
              <h5>
                GENERAL
                <br />
                <br />
                <br />
                Please read this before using the Zanobid website or submitting
                any personal information therein. The following Privacy Policy
                outlines the information Zanobid may collect and how we may use
                it. Zanobid is a service provided by Zipawoofs Limited.
                <br />
                <br />
                We acknowledge and respect your personal privacy, according to
                statutory regulations, in particular, the Nigeria Data
                Protection Regulation [NDPR] 2019, the Nigeria Constitution
                among others.
                <br />
                <br />
                We do need some personal information from you so that you can
                actively participate in Zanobid Auctions. We collect only the
                Personal Information you provide to us. We would therefore like
                to request that you agree to the collection and storage of some
                of your data.
                <br />
                <br />
                <br />
                DATA COLLECTION
                <br />
                <br />
                Personal data is the information needed to identify you, for
                example your name, address, date of birth, e-mail address,
                mobile phone number, etc. With your consent, personal data is
                recorded and processed by Zanobid. Also other non-personal
                information may be stored, such as: when you register with
                Zanobid; when you subscribe; when you bid on or win an auction;
                what payment services you use; your IP address. To protect our
                customers by minimising the number of places your sensitive
                payment information is stored, Zanobid ensures that payment
                account or credit card data other than your billing address is
                never stored. Our website contains forms that directly pass such
                information on to our payment processor; the only part stored in
                our system is the service used, the date of the transaction, if
                it was successful and any error messages provided by the payment
                processor. As stated above, Zanobid online services store
                bidding data with timestamps when credits were placed. This
                information can be used by Zanobid for enhancing Zanobid online
                bidding service. It is important to note that no personal data
                of a user will ever be shared with any third party except where
                required for the smooth running of the platform or any other
                Zanobid user.
                <br />
                <br />
                <br />
                USE OF THE DATA
                <br />
                <br />
                Personal data such as name, address, etc. is used to process
                product delivery after an auction is won. We ask for your email
                address so that we can stay in contact with you by email. We
                also use your personal data to communicate with you regarding
                orders, to process auctions, marketing retention and to process
                other transactions. Similarly, we use your data in order to
                inform you of marketing promotions or to enable third parties
                authorized by us to perform technical, logistical, or other
                services. All data is stored securely on our servers.
                <br />
                <br />
                <br />
                EMAIL NEWSLETTERS
                <br />
                <br />
                When you register with Zanobid, you agree to receive email
                newsletters. We will use this method to inform you of new
                functions or special promotions on our website. You can at any
                time opt-out of this by changing your settings where applicable.
                <br />
                <br />
                <br />
                VIEWING/CHANGING PERSONAL DATA
                <br />
                <br />
                You can access, review and change most of your personal
                information by logging on to Zanobid at any time. You are
                required to promptly update your personal information if changes
                occur or information is inaccurate.
                <br />
                <br />
                <br />
                THE USE OF COOKIES AT OUR WEBSITE
                <br />
                <br />
                Zanobid uses so-called “cookies”, which are alphanumeric
                identifiers that are stored on your computer that can be
                retrieved to assist in customizing your experience with the
                online service. The information saved supports the functionality
                of the site, for example to recognize your browser and to
                provide features like keeping track of your visual preferences
                or controlling the frequency of “pop-up” windows. You are free
                to prevent cookies from being saved on your hard drive by
                adjusting the corresponding settings in your browser. However,
                turning off these settings may result in limited functionality.
                <br />
                <br />
                <br />
                SECURITY MEASURES
                <br />
                <br />
                The information in your Zanobid account and in your Zanobid
                profile is password protected, so that only you have access to
                this personal information. Please note that you are not
                permitted to disclose your password to anyone else. Zanobid will
                never ask for your password in e-mails that you receive
                unexpectedly or that you did not request. Please remember to log
                out of your account and to close your internet browser window
                when you leave the Zanobid site; this is especially important if
                you use a PC in public locations. At present, this is the safest
                way to ensure that no one has access to your personal
                information. We assume no liability for the abuse of login data
                and passwords used.
                <br />
                <br />
                <br />
                PRIVACY POLICY AMENDMENTS
                <br />
                <br />
                We may amend this Privacy Policy at any time by posting the new
                Privacy Policy on Zanobid. Zanobid reserves the right to change
                this Privacy Policy at any time without notice to you.
                Consequently, you should review this Privacy Policy for changes
                each time you visit Zanobid.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Policy;
