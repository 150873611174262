import React from "react";
import FooterLogin from "./footer-login";

function Footer() {
  return (
    <footer className="kab-footer kab-bg-darkest za-white">
      <div className="back-to-top kab-bg-dark text-center">
        <a type="button" className="btn py-2" id="btn-back-to-top" href="#">
          <i className="fas fa-angle-up"></i>
          <br />
          TOP OF PAGE
        </a>
      </div>
      <div className="footer-menu kab-bg-darker section-pd">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <ul className="list-unstyled">
                <li>
                  <a href="/">zanobid.com</a>
                </li>
                <li>
                  <a href="about">About Us</a>
                </li>
                <li>
                  <a href="how-it-works">How It Works</a>
                </li>
                <li>
                  <a href="faq">FAQs</a>
                </li>
                <li>
                  <a href="winning-tips">Winning Tips</a>
                </li>
                <li>
                  <a href="contact-us">Contact Us</a>
                </li>
              </ul>
            </div>
            <div className="col-6">
              <ul className="list-unstyled">
                {/* <li>
                  <a
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#dummyGame"
                  >
                    Play a Test Game
                  </a>
                </li>
                <li>
                  <a href="referral-bonus.html">Referral & Bonus</a>
                </li>
                <li>
                  <a href="bid-history.html">Your Bid History</a>
                </li> */}
                <li>
                  <a href="#">Your Account</a>
                </li>
                {/* <li>
                  <a href="calendar.html">Check Our Calendar</a>
                </li>
                <li>
                  <a target="_blank" href="fba-ag.pdf">
                    Advertise with Us
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer section-pd text-center">
        <div className="language-currency section-pd">
          <a href="#">
            <i className="fas fa-globe-africa"></i>
            <span>English</span>
          </a>
          <a href="#">
            ₦<span>NGN - Naira</span>
          </a>
          <a href="#" style={{ display: "block" }}>
            <img
              className="img-flag"
              src="https://purecatamphetamine.github.io/country-flag-icons/3x2/NG.svg"
              alt="NG"
            />
            <span>Nigeria</span>
          </a>
        </div>
        <FooterLogin />
      </div>
    </footer>
  );
}

export default Footer;
