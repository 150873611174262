import React, { useEffect } from "react";
import logo from "../../assets/images/logo.png";
import logo2 from "../../assets/images/logo2.png";
import nucleus from "../../assets/images/icons/nucleuswave.svg";
import FooterLogin from "../../components/footer/footer-login";
import LoginForm from "../../components/forms/login";
import { reset } from "../../redux/slice/user";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

function Login() {
  const { phonenumber } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(reset());
  }, []);

  return (
    <div style={{ height: "100vh" }}>
      <section className="section-pd">
        <div className="section-pd text-center">
          <a href="/">
            <img style={{ width: "15%" }} src={nucleus} alt="nucleuswave" />
          </a>
        </div>
        <div className="">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-4 col-11">
                <div className="za-bg-white p-3 kab-login">
                  <LoginForm />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
