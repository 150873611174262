import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import Select from "react-select";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { SignupSchema } from "../../../util/validation/validation";
import SignupSelect from "./signupSelect";
import { useDispatch, useSelector } from "react-redux";
import { signupT } from "../../../redux/slice/user";
import { Bars } from "react-loader-spinner";
import { Link } from "react-router-dom";

function SignupForm({ phonenumber }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState();
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };
  const { signupLoading } = useSelector((state) => state.user);

  const options = [
    // {
    //   value: ' ₦10,000 / Month (All items including PREMIUM items)',
    //   label: ' ₦10,000 / Month (All items including PREMIUM items)',
    // },
    {
      value:
        "₦50, ₦100 or ₦200 (MTN Airtime) / day (All items without PREMIUM items)",
      label:
        "₦50, ₦100 or ₦200 (MTN Airtime) / day (All items without PREMIUM items)",
    },
    // {
    //   value: ' ₦100,000 / Year (All items including PREMIUM items)',
    //   label: ' ₦100,000 / Year (All items including PREMIUM items)',
    // },
    // {
    //   value: '   ₦50,000 / Year (All items without PREMIUM items)',
    //   label: '   ₦50,000 / Year (All items without PREMIUM items)',
    // },
  ];

  const initialValues = {
    email: "",
    businessName: "",
    phonenumber: "",
    password: "",
  };

  const submitForm = (values) => {
    const data = {
      businessName: values.businessName,
      email: values.email,
      password: values.password,
      phoneNumber: values.phonenumber,
    };
    dispatch(signupT(data));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SignupSchema}
      onSubmit={submitForm}
    >
      {(formik) => {
        const { isValid, dirty } = formik;

        return (
          <Form>
            <div class="mb-4">
              <h3 class="fw-bold">Create Account</h3>
              {/* <p class="small">
                Your account will be automatically created when you subscribe.
              </p> */}
            </div>
            {/* <div class="mb-3">
              <label>Select your package</label>
              <Field
                name="package"
                options={options}
                component={SignupSelect}
              />
            </div> */}
            <div className="mb-3">
              <label> Business Name </label>
              <div className="input-group">
                <Field
                  name="businessName"
                  id="businessName"
                  type="text"
                  className="form-control"
                  placeholder="Enter Business Name"
                />
              </div>
              <ErrorMessage
                name="businessName"
                component="span"
                className="error"
              />
            </div>

            <div className="mb-3">
              <label> Email </label>
              <div className="input-group">
                <Field
                  name="email"
                  id="email"
                  type="text"
                  className="form-control"
                  placeholder="Enter Email"
                />
              </div>
              <ErrorMessage name="email" component="span" className="error" />
            </div>

            <label>Phone number</label>

            <div class="mb-3">
              <Field name="phonenumber">
                {({ field, form }) => (
                  <>
                    <PhoneInput
                      {...field}
                      placeholder="Enter phone number"
                      onChange={(value) =>
                        form.setFieldValue("phonenumber", value)
                      }
                      defaultCountry="NG"
                    />
                    <ErrorMessage
                      name="phonenumber"
                      component="span"
                      className="error"
                    />
                  </>
                )}
              </Field>
            </div>

            <div className="mb-3">
              <label>Password</label>
              <div className="input-group">
                <Field
                  name="password"
                  id="password"
                  type="password"
                  className="form-control"
                  placeholder="Enter Password"
                />
              </div>
              <ErrorMessage
                name="password"
                component="span"
                className="error"
              />
            </div>

            <div class="d-grid mb-3">
              <button
                class="n-btn btn-primary btn-lg kab-bg-midred border-0 fw-bold"
                type="submit"
                disabled={!(dirty && isValid)}
              >
                {signupLoading ? (
                  <div className="d-flex justify-content-center">
                    <Bars
                      height="30"
                      width="30"
                      color="#FFF"
                      ariaLabel="bars-loading"
                      wrapperclassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  "Create Account"
                )}
              </button>
            </div>
            <div class="mb-4">
              <p class="small">
                By continuing, you agree to Nucleuswave's <br />
              </p>
            </div>
            <hr />
            <div class="mb-3">
              <p>
                Already have Username? <Link to="/login">Sign-In ›</Link>
              </p>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default SignupForm;
