import Modal from "react-bootstrap/Modal";
import product from "../../assets/images/product.jpg";
import RenderCountdown from "../auction/renderCountdown";
import { useNavigate } from "react-router-dom";
import useSound from "use-sound";
import { useEffect, useState } from "react";
import outBid from "../../assets/sounds/outbid.mp3";
import bidTone from "../../assets/sounds/bid.mp3";
import { testName, testNameWinner } from "../../data/gamer";
import { generateRandomNumber } from "../../util/helper";

export function TestAuction({ handleShow, setGetReadyTime }) {
  const navigate = useNavigate();
  const [playOutBid] = useSound(outBid);

  const [timeStyle, setTimeStyle] = useState();
  const [bidder, setBidder] = useState();

  const [startDateTime, setStartDate] = useState();
  const [endDateTime, setEndDateTime] = useState();
  const [playAgain, setPlayAgain] = useState(false);
  const [winner, setWinner] = useState("");
  const [bidCount, setBidCount] = useState(0);

  const generalTest = () => {
    const number = generateRandomNumber(0, 7);
    const bidder = testName[number];

    setBidder(bidder);
  };

  const getWinner = () => {
    const number = generateRandomNumber(0, 7);
    const bidder = testNameWinner[number];

    setWinner(bidder);
  };

  const personalTest = () => {
    setBidder("you");
    setBidCount(bidCount + 1);
  };

  useEffect(() => {
    let currentDate = new Date();
    // Add 3 minutes to the current date
    currentDate.setMinutes(currentDate.getMinutes() + 1);
    let currentTimeISO = new Date().toISOString();
    // Convert the updated date to an ISO string
    let endTime = currentDate.toISOString();

    setStartDate(new Date(currentTimeISO));
    setEndDateTime(new Date(endTime));
  }, [playAgain]);

  const getPerFromTime = () => {
    const time = new Date();
    const diff = endDateTime?.getTime() - startDateTime?.getTime();
    const currentDiff = endDateTime?.getTime() - time;
    let percent = (1 - currentDiff / diff) * 100;
    percent = Math.ceil(percent);
    // console.log('percent', percent, endDateTime);
    setTimeStyle(percent);
  };

  useEffect(() => {
    let timer;
    const time = new Date();

    let timerTime = 1000;

    timer = setInterval(() => {
      if (new Date() < endDateTime) {
        setPlayAgain(false);
        generalTest();
        if (new Date() > startDateTime) {
          playOutBid();
          getPerFromTime();
        }
      }
      if (new Date() > endDateTime) {
        clearInterval(timer);

        if (bidCount > 5) {
          setWinner("you");
        } else {
          getWinner();
        }
      }
    }, timerTime);

    if (time > endDateTime) {
      setTimeStyle(100);
    }
    if (time < startDateTime) {
      setTimeStyle(0);
    }

    return () => {
      clearInterval(timer);
    };
  }, [endDateTime, startDateTime, playAgain, bidCount]);

  useEffect(() => {
    let currentTimer;
    let finalTimer;

    const time = new Date();
    const currentDiff = endDateTime?.getTime() - time.getTime();
    const timeToGetCurrent = currentDiff - 30000;
    const timeToGetFinal = currentDiff - 60000;

    if (currentDiff < 60000) {
    }

    currentTimer = setTimeout(() => {}, timeToGetCurrent);

    finalTimer = setTimeout(() => {}, timeToGetFinal);

    return () => {
      clearTimeout(currentTimer);
      clearTimeout(finalTimer);
    };
  }, [endDateTime]);

  return (
    <div class="row justify-content-center">
      <div className="col-6 col-lg-5 col-sm-3">
        <div className="za-product kab-product kab-bg-darker za-white">
          <h6 className="prod-name"> Iphone 13 Pro Max </h6>

          <div className="prod-img">
            <img src={product} alt="auction" />
            {new Date() > endDateTime && (
              <div className="last20-bids">
                <button
                  onClick={() => {
                    setPlayAgain(true);
                    setBidCount(0);
                  }}
                  type="button"
                  className="btn kab-bg-midred btn-lg"
                >
                  Play Again
                </button>
              </div>
            )}
          </div>
          {
            <div className="progress-line">
              <span
                style={{
                  width: `${timeStyle}%`,
                }}
                className="progress kab-bg-midred"
              ></span>
            </div>
          }
          <div className="za-decider">
            {renderBidHighlight(startDateTime, endDateTime)}
          </div>
          <AuctionName
            startTime={startDateTime}
            endTime={endDateTime}
            bidder={bidder}
            winner={winner}
          />

          <div className="d-grid">
            <AuctionTestButton
              handleShow={handleShow}
              setGetReadyTime={setGetReadyTime}
              startTime={startDateTime}
              endTime={endDateTime}
              bidder={bidder}
              personalTest={personalTest}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const renderBidHighlight = (startTime, endTime) => {
  const time = new Date();
  const startDateTime = new Date(startTime);
  const endDateTime = new Date(endTime);

  if (time < startDateTime) {
    return (
      <h6 className="za-time">
        <span>Bidding starts</span> <RenderCountdown time={startDateTime} />
      </h6>
    );
  }

  if (time >= startDateTime && time < endDateTime) {
    return (
      <h6 className="za-time">
        <span>BID CLOSES IN </span> <RenderCountdown time={endDateTime} />
      </h6>
    );
  }
};

const AuctionName = ({ startTime, endTime, bidder, winner }) => {
  const time = new Date();
  const startDateTime = new Date(startTime);
  const endDateTime = new Date(endTime);

  if (time < startDateTime) {
    return (
      <p className="za-name">
        <span className="highlight">name</span> just joined
      </p>
    );
  }

  if (time >= startDateTime && time <= endDateTime) {
    return bidder === "you" ? (
      <p className="za-name">
        <span className="highlight">{bidder}</span> are bidding
      </p>
    ) : (
      <p className="za-name">
        <span className="highlight">{bidder}</span> is bidding
      </p>
    );
  }

  if (time > endDateTime) {
    return (
      <p className="za-name">
        <span className="highlight">{winner}</span> have won
      </p>
    );
  }
};

export const AuctionTestButton = ({ startTime, endTime, personalTest }) => {
  const time = new Date();
  const navigate = useNavigate();
  const startDateTime = new Date(startTime);
  const endDateTime = new Date(endTime);
  const [sendCount, setSendCount] = useState(1);
  const [playBidTone] = useSound(bidTone);
  // const check200 = true;

  const bid = () => {
    playBidTone();
    personalTest();
  };

  if (time < startDateTime) {
    return (
      <button className="btn btn-lg kab-bg-dark">Joined. Get Ready</button>
    );
  } else if (time >= startDateTime && time <= endDateTime) {
    return (
      <button
        onClick={() => {
          bid();
        }}
        className="btn btn-block btn-lg amazon-secondary-button"
      >
        BID NOW
      </button>
    );
  } else if (time > endDateTime) {
    return (
      <button
        className="btn btn-lg kab-bg-dark"
        // data-bs-toggle="modal"
        // data-bs-target="#getReady"
      >
        Auction Closed
      </button>
    );
  }
};

function TestGameModal({ show, handleClose }) {
  return (
    <Modal dialog show={show} onHide={handleClose}>
      <div>
        <div className="text-center mx-3">
          <h2 className="mt-3">Play A Test Game </h2>
          <p>
            It is very easy to win on Zanobid. The continuous and last bidder
            wins.
          </p>
        </div>
        <div className="kab-bg-darkest">
          <TestAuction />
        </div>
        <div className="mx-4 my-3">
          <div class="d-grid gap-2">
            <button
              onClick={handleClose}
              class="btn test-btn kab-bg-dark"
              type="button"
            >
              Play Real Game Now
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default TestGameModal;
