import * as Yup from "yup";

export const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid Email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password should be at least 8 characters")
    .required("Password is required"),
});

export const SignupSchema = Yup.object().shape({
  phonenumber: Yup.string()
    .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, "Invalid phone number")
    .min(10, "Invalid phone number")
    .min(11, "Invalid phone number")
    .required("Phone number is required"),

  businessName: Yup.string()
    .min(3, "Business Name should be at least 3 characters")
    .max(50, "Business Name is too long ")
    .required("Business Name is required"),
  email: Yup.string()
    .email("Invalid Email address")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Password should be at least 8 characters")
    .required("Password is required"),
});

export const collectionSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Name should be at least 3 characters")
    .required("Name is required"),

  collection: Yup.string().min(3, "Name should be at least 3 characters"),
});

export const SignupGatewaySchema = Yup.object().shape({
  phonenumber: Yup.string()
    .matches(/^\+([1-9][0-9]{0,14})$/, "Invalid phone number")
    .min(14, "Invalid phone number")
    .max(14, "Invalid phone number")

    .required("Phone number is required"),

  username: Yup.string()
    .matches(/^[^&@=_""'\-+,<>]+$/, {
      message:
        "Field cannot contain ampersand (&), equals sign (=), underscore (_), apostrophe ('), dash (-), plus sign (+), comma (,), brackets (<,>), or more than one period (.)",
    })
    .required("Password is required")
    .test("space", "space is not allowed", (value) => {
      // Use a regular expression to check for spaces
      return !/\s/.test(value);
    })
    .max(10, "Field must be less than 10 characters long"),
});

export const messageSchema = Yup.object().shape({
  message: Yup.string().required("message is required"),
});

export const paymentSchema = Yup.object().shape({
  amount: Yup.string().required("amount is required"),
});

export const ForgetPasswordSchema = Yup.object().shape({
  phonenumber: Yup.string()
    .matches(/^\+([1-9][0-9]{0,14})$/, "Invalid phone number")
    .min(14, "Invalid phone number")
    .max(14, "Invalid phone number")

    .required("Phone number is required"),
});
