import { flatMap } from 'lodash-es';
import { useMemo, useState } from 'react';
import { getDataInStorage } from '../util/storage';
import { useSelector } from 'react-redux';

export const useAuth = () => {
  const { accesstoken } = useSelector((state) => state.user);

  if (accesstoken) {
    return true;
  } else {
    return false;
  }
};
