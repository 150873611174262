import React, { useEffect } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

const Payment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let getStatus = searchParams.get('status');

  //   if (getStatus) {
  //     console.log('true');
  //     navigate('/');
  //   }
  useEffect(() => {
    if (getStatus === 'successful') {
      navigate('/login');
    } else {
      navigate('/signup-next');
    }
  }, [getStatus]);
  return <div></div>;
};

export default Payment;
