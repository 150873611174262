import React from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import { useAuth } from "../../hooks/auth";
import { Link } from "react-router-dom";

function Subscribe() {
  const auth = useAuth();
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const handleSendSMS50 = () => {
    const phoneNumber = "205"; // Replace with the recipient's phone number
    const message = "ZA1"; // Replace with the desired pre-filled message

    const smsURL = `sms:${phoneNumber}?body=${encodeURIComponent(message)}`;

    const smsURLIOS = `sms:${phoneNumber}`;

    if (isIOS) {
      window.open(smsURLIOS);
    } else {
      window.open(smsURL);
    }
  };

  const handleSendSMS100 = () => {
    const phoneNumber = "205"; // Replace with the recipient's phone number
    const message = "ZA2"; // Replace with the desired pre-filled message

    const smsURL = `sms:${phoneNumber}?body=${encodeURIComponent(message)}`;
    const smsURLIOS = `sms:${phoneNumber}`;

    if (isIOS) {
      window.open(smsURLIOS);
    } else {
      window.open(smsURL);
    }
  };

  const handleSendSMS200 = () => {
    const phoneNumber = "205"; // Replace with the recipient's phone number
    const message = "ZA3"; // Replace with the desired pre-filled message

    const smsURL = `sms:${phoneNumber}?body=${encodeURIComponent(message)}`;

    const smsURLIOS = `sms:${phoneNumber}`;

    if (isIOS) {
      window.open(smsURLIOS);
    } else {
      window.open(smsURL);
    }
  };

  const handleSendSMS2000 = () => {
    const phoneNumber = "205"; // Replace with the recipient's phone number
    const message = "ZA4"; // Replace with the desired pre-filled message

    const smsURL = `sms:${phoneNumber}?body=${encodeURIComponent(message)}`;

    const smsURLIOS = `sms:${phoneNumber}`;

    if (isIOS) {
      window.open(smsURLIOS);
    } else {
      window.open(smsURL);
    }
  };

  const handleSendSMS5000 = () => {
    const phoneNumber = "205"; // Replace with the recipient's phone number
    const message = "ZA5"; // Replace with the desired pre-filled message

    const smsURL = `sms:${phoneNumber}?body=${encodeURIComponent(message)}`;

    const smsURLIOS = `sms:${phoneNumber}`;

    if (isIOS) {
      window.open(smsURLIOS);
    } else {
      window.open(smsURL);
    }
  };

  return (
    <div>
      {" "}
      <Header auth={auth} />
      <section style={{ background: "#fff" }} class="za-top-up">
        <div class="container">
          <div class="row justify-content-md-center">
            <div class="col-sm-4">
              <h4 class="main-title">Select Your Package</h4>
              <p>You can cancel your subscription at anytime.</p>
              <Link onClick={handleSendSMS50} class="pay-card">
                <div class="row">
                  <div class="col-11 my-auto">
                    <h5>₦50 Daily</h5>
                    <p>
                      Text <span class="underlined">ZA1</span> to{" "}
                      <span class="underlined">205</span>
                    </p>
                  </div>
                  <div class="col-1 p-0 my-auto">
                    <svg
                      width="9"
                      height="14"
                      viewBox="0 0 9 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.299072 11.8768L5.3863 6.78953L0.299072 1.72879L1.86233 0.165527L8.48633 6.78953L1.86233 13.4135L0.299072 11.8768Z"
                        fill="#37475A"
                      />
                    </svg>
                  </div>
                </div>
              </Link>
              <a onClick={handleSendSMS200} class="pay-card">
                <div class="row">
                  <div class="col-11 my-auto">
                    <h5>
                      ₦200 Daily<span>BEST</span>
                    </h5>
                    <p>
                      Text <span class="underlined">ZA3</span> to{" "}
                      <span class="underlined">205</span>
                    </p>
                  </div>
                  <div class="col-1 p-0 my-auto">
                    <svg
                      width="9"
                      height="14"
                      viewBox="0 0 9 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.299072 11.8768L5.3863 6.78953L0.299072 1.72879L1.86233 0.165527L8.48633 6.78953L1.86233 13.4135L0.299072 11.8768Z"
                        fill="#37475A"
                      />
                    </svg>
                  </div>
                </div>
              </a>
              <a onClick={handleSendSMS100} class="pay-card">
                <div class="row">
                  <div class="col-11 my-auto">
                    <h5>₦100 Daily</h5>
                    <p>
                      Text <span class="underlined">ZA2</span> to{" "}
                      <span class="underlined">205</span>
                    </p>
                  </div>
                  <div class="col-1 p-0 my-auto">
                    <svg
                      width="9"
                      height="14"
                      viewBox="0 0 9 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.299072 11.8768L5.3863 6.78953L0.299072 1.72879L1.86233 0.165527L8.48633 6.78953L1.86233 13.4135L0.299072 11.8768Z"
                        fill="#37475A"
                      />
                    </svg>
                  </div>
                </div>
              </a>
              <a onClick={handleSendSMS2000} class="pay-card">
                <div class="row">
                  <div class="col-11 my-auto">
                    <h5> ₦2000(10 Days)</h5>
                    <p>
                      Text <span class="underlined">ZA4</span> to{" "}
                      <span class="underlined">205</span>
                    </p>
                  </div>
                  <div class="col-1 p-0 my-auto">
                    <svg
                      width="9"
                      height="14"
                      viewBox="0 0 9 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.299072 11.8768L5.3863 6.78953L0.299072 1.72879L1.86233 0.165527L8.48633 6.78953L1.86233 13.4135L0.299072 11.8768Z"
                        fill="#37475A"
                      />
                    </svg>
                  </div>
                </div>
              </a>
              <a onClick={handleSendSMS5000} class="pay-card">
                <div class="row">
                  <div class="col-11 my-auto">
                    <h5> ₦5000(30 Days)</h5>
                    <p>
                      Text <span class="underlined">ZA5</span> to{" "}
                      <span class="underlined">205</span>
                    </p>
                  </div>
                  <div class="col-1 p-0 my-auto">
                    <svg
                      width="9"
                      height="14"
                      viewBox="0 0 9 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.299072 11.8768L5.3863 6.78953L0.299072 1.72879L1.86233 0.165527L8.48633 6.78953L1.86233 13.4135L0.299072 11.8768Z"
                        fill="#37475A"
                      />
                    </svg>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer />{" "}
    </div>
  );
}

export default Subscribe;
