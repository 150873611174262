import React from "react";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";

const Contact = () => {
  return (
    <>
      <Header />
      <div class="abx-fixed-top">
        <section class="inner-hero text-center">
          <div class="container">
            <div class="row">
              <div class="col-xl-9 mx-auto">
                <h1 class="mb-5">Contact Us</h1>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section class="inner-body bg-white faq">
        <div class="container">
          <div class="row  justify-content-md-center">
            <div class="col-md-8">
              <h5>
                The Zanobid customer support team is always ready to answer your
                questions and provide all the necessary assistance.
                <br />
                <br />
                You can contact us by chatting with us anytime on our social
                media pages.
                <br />
                <br />
                Check out our{" "}
                <a href="https://zanobid.com/faq">Frequently Asked Questions</a>
              </h5>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Contact;
