import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  forgetPasswordService,
  getSubscriptionsPlanService,
  initiateSubscriptionsService,
  loginService,
  signupService,
  SubscriptionDetailsService,
} from "../services/user/user";
import { toaster } from "../../notification/toaster";
import { setDataInStorage } from "../../util/storage";
import {
  downloadReportService,
  sendMessageService,
} from "../services/auctions/auction";

const initialState = {
  userId: "",
  loginLoading: false,
  loginError: "",
  loginErrorMessage: "",
  activeSubscription: false,
  hasPaidOnce: false,
  accesstoken: "",
  role: "",
  firstname: "",
  userName: "",
  subscriptionPlans: [],
  signupLoading: false,
  signupSuccessful: false,
  subscriptionExpiryDate: "",
  lastSubscribedPlan: "",
  hasACurrentValidSubscriptionFor2kAnd5K: false,
  paymentGateway: "",
  paymentLink: "",
  subscriptionEndingDate2kOr5k: "",
  subscriptionIdFor2kOr5k: "",
  isLoadingForgetPassword: false,
  forgetPasswordSuccess: false,
  fundWalletLoading: false,
  balance: 0,
  messageLoading: false,
  messageSuccess: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: () => initialState,
    setPayment: (state, action) => {
      state.paymentGateway = action.payload;
    },
    resetPayment: (state, action) => {
      state.paymentLink = "";
    },
    resetMessageSuccess: (state, action) => {
      state.messageSuccess = false;
    },
    resetForgetPasswordSuccess: (state, action) => {
      state.forgetPasswordSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // login user
      .addCase(loginT.pending, (state) => {
        state.loginLoading = true;
      })
      .addCase(loginT.fulfilled, (state, action) => {
        state.loginLoading = false;
        state.accesstoken = action.payload.accessToken;
        state.firstname = action.payload.firstName;
        state.userId = action.payload?.subscriptionId;
        setDataInStorage("accesstoken", action.payload.accessToken);
        state.role = action.payload.role;
        state.hasPaidOnce = action.payload.hasDoneAtLeastOneSubcription;
        state.activeSubscription = action.payload.hasACurrentValidSubscription;
        state.userName = action.payload.userName;
        state.balance = action.payload.balance;
        state.subscriptionEndingDate2kOr5k =
          action?.payload?.subscriptionEndingDate2kOr5k;
        state.hasACurrentValidSubscriptionFor2kAnd5K =
          action.payload?.hasACurrentValidSubscriptionFor2kAnd5K;
        state.subscriptionIdFor2kOr5k = action.payload?.subscriptionIdFor2kOr5k;
        toaster("success", "Login successful");
      })
      .addCase(loginT.rejected, (state, action) => {
        state.loginLoading = false;
        state.lastSubscribedPlan = action.payload?._id;

        toaster("error", action.payload.message);
      })
      .addCase(signupT.pending, (state) => {
        state.signupLoading = true;
        state.signupSuccessful = false;
      })
      .addCase(signupT.fulfilled, (state, action) => {
        state.signupLoading = false;
        setDataInStorage("accesstoken", action.payload.accessToken);
        state.accesstoken = action.payload.accessToken;
        state.userId = action.payload?.subscriptionId;
        state.userName = action.payload?._doc.userName;
        state.balance = action.payload?.balance;
        state.hasACurrentValidSubscriptionFor2kAnd5K =
          action.payload?.hasACurrentValidSubscriptionFor2kAnd5K;
        state.subscriptionEndingDate2kOr5k =
          action?.payload?.subscriptionEndingDate2kOr5k;
        state.signupSuccessful = true;
        state.subscriptionIdFor2kOr5k = action.payload?.subscriptionIdFor2kOr5k;
      })
      .addCase(signupT.rejected, (state, action) => {
        state.signupLoading = false;
        state.signupSuccessful = false;
        toaster("error", action.payload.message);
      })
      .addCase(getSubscriptionsPlanT.pending, (state) => {})
      .addCase(getSubscriptionsPlanT.fulfilled, (state, action) => {
        state.subscriptionPlans = action.payload;
        setDataInStorage("plans", action.payload);
      })
      .addCase(getSubscriptionsPlanT.rejected, (state, action) => {})
      .addCase(SubscriptionDetailsT.pending, (state) => {})
      .addCase(SubscriptionDetailsT.fulfilled, (state, action) => {
        state.subscriptionExpiryDate =
          action.payload?.expectedExpiryOfLastPaidSubscriptionPayment;

        state.lastSubscribedPlan = action.payload?.lastKnownSubscriptionPlanId;
      })
      .addCase(SubscriptionDetailsT.rejected, (state, action) => {
        state.subscriptionExpiryDate = "";
        state.lastSubscribedPlan = "";
      })
      .addCase(initiateSubscriptionT.pending, (state) => {
        state.fundWalletLoading = true;
      })
      .addCase(initiateSubscriptionT.fulfilled, (state, action) => {
        state.fundWalletLoading = false;
        state.paymentLink = action.payload?.link?.data?.authorization_url;
      })
      .addCase(initiateSubscriptionT.rejected, (state, action) => {
        state.fundWalletLoading = false;
        toaster("error", action.payload.message);
      })
      .addCase(forgetPasswordT.pending, (state) => {
        state.isLoadingForgetPassword = true;
      })
      .addCase(forgetPasswordT.fulfilled, (state, action) => {
        state.isLoadingForgetPassword = false;
        if (action.payload.success === true) {
          toaster("success", "Your username successfully sent via sms");
          state.forgetPasswordSuccess = true;
        }
      })
      .addCase(forgetPasswordT.rejected, (state, action) => {
        state.isLoadingForgetPassword = false;
        if (action.payload.message) {
          toaster("error", action.payload.message);
        }
      })
      .addCase(sendMessageT.pending, (state) => {
        state.messageLoading = true;
        state.messageSuccess = false;
      })
      .addCase(sendMessageT.fulfilled, (state, action) => {
        state.messageLoading = false;
        state.messageSuccess = true;

        state.balance = action.payload.balance;
        toaster("success", "Blast Successfully Sent");
      })
      .addCase(sendMessageT.rejected, (state, action) => {
        state.messageLoading = false;
        state.messageSuccess = false;
        toaster("error", action.payload.message);
      })
      .addCase(downloadReportT.pending, (state) => {
        // state.messageLoading = true;
        toaster("success", "downloading... begins shortly");
      })
      .addCase(downloadReportT.fulfilled, (state, action) => {
        // state.messageLoading = false;
        // state.balance = action.payload.balance;
        // toaster("success", "Blast Successfully Sent");
      })
      .addCase(downloadReportT.rejected, (state, action) => {
        // state.messageLoading = false;
        // toaster("error", action.payload.message);
      });
  },
});

export const loginT = createAsyncThunk("user/login", async (data, thunkAPI) => {
  const timeoutId = setTimeout(() => {
    thunkAPI.rejectWithValue("Timeout");
  }, 5000);

  try {
    const response = await loginService(data);
    clearTimeout(timeoutId);
    return response;
  } catch (error) {
    if (error?.response) {
      // Handle HTTP errors (e.g., 404, 500)
      clearTimeout(timeoutId);
      return thunkAPI.rejectWithValue(error.response?.data);
    } else {
      // Handle other errors
      clearTimeout(timeoutId);

      console.log("Error", error.message);
      toaster("warn", `${error.message} Check your network connection`);
      return thunkAPI.rejectWithValue(error.message);
    }
  }
});

export const signupT = createAsyncThunk(
  "user/signup",
  async (data, thunkAPI) => {
    const timeoutId = setTimeout(() => {
      thunkAPI.rejectWithValue("Timeout");
    }, 5000);

    try {
      const response = await signupService(data);
      clearTimeout(timeoutId);
      return response;
    } catch (error) {
      if (error?.response) {
        // Handle HTTP errors (e.g., 404, 500)
        clearTimeout(timeoutId);
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        // Handle other errors
        clearTimeout(timeoutId);

        console.log("Error", error.message);
        toaster("warn", `${error.message} Check your network connection`);
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const forgetPasswordT = createAsyncThunk(
  "user/forget-password",
  async (data, thunkAPI) => {
    try {
      const response = await forgetPasswordService(data);

      return response;
    } catch (error) {
      if (error?.response) {
        // Handle HTTP errors (e.g., 404, 500)

        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        // Handle other errors

        console.log("Error", error.message);
        toaster("warn", `${error.message} Check your network connection`);
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const getSubscriptionsPlanT = createAsyncThunk(
  "user/get-plans",
  async (data, thunkAPI) => {
    try {
      const response = await getSubscriptionsPlanService();

      return response;
    } catch (error) {
      if (error?.response) {
        // Handle HTTP errors (e.g., 404, 500)

        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        // Handle other errors

        console.log("Error", error.message);
        toaster("warn", `${error.message} Check your network connection`);
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const initiateSubscriptionT = createAsyncThunk(
  "user/initiate-payment",
  async (data, thunkAPI) => {
    try {
      const response = await initiateSubscriptionsService(data);

      return response;
    } catch (error) {
      if (error?.response) {
        // Handle HTTP errors (e.g., 404, 500)

        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        // Handle other errors
        console.log("error response", error.response);
        if (error.response && error.response.status === 401) {
          console.log("session Timeout");
        }
        console.log("Error", error);
        toaster("warn", `${error.message} Check your network connection`);
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const sendMessageT = createAsyncThunk(
  "send/message",
  async (data, thunkAPI) => {
    try {
      const response = await sendMessageService(data);

      return response;
    } catch (error) {
      if (error?.response) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        console.log("Error", error.message);
      }
    }
  }
);

export const downloadReportT = createAsyncThunk(
  "download/report",
  async (data, thunkAPI) => {
    try {
      const response = await downloadReportService(data);

      return response;
    } catch (error) {
      if (error?.response) {
        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        console.log("Error", error.message);
      }
    }
  }
);

export const SubscriptionDetailsT = createAsyncThunk(
  "user/subscription-details",
  async (data, thunkAPI) => {
    try {
      const response = await SubscriptionDetailsService();

      return response;
    } catch (error) {
      if (error?.response) {
        // Handle HTTP errors (e.g., 404, 500)

        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        // Handle other errors
        console.log("error response", error.response);
        console.log("Error", error);
        toaster("warn", `${error.message} Check your network connection`);
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const {
  reset,
  setPayment,
  resetPayment,
  resetForgetPasswordSuccess,
  resetMessageSuccess,
} = userSlice.actions;

export default userSlice.reducer;
