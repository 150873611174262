import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getSubscriptionsPlanService,
  initiateSubscriptionsService,
  loginService,
  signupService,
  SubscriptionDetailsService,
} from "../services/user/user";
import { toaster } from "../../notification/toaster";
import { setDataInStorage } from "../../util/storage";
import { getRaffleWinnerService } from "../services/raffle/raffle";

const initialState = {
  raffleWinner: [],
};

const raffleSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      // login user
      .addCase(getRaffleWinnerT.pending, (state) => {})
      .addCase(getRaffleWinnerT.fulfilled, (state, action) => {
        state.raffleWinner = action.payload;
      })
      .addCase(getRaffleWinnerT.rejected, (state, action) => {});
  },
});

export const getRaffleWinnerT = createAsyncThunk(
  "raffle/winner",
  async (data, thunkAPI) => {
    try {
      const response = await getRaffleWinnerService();

      return response;
    } catch (error) {
      if (error?.response) {
        // Handle HTTP errors (e.g., 404, 500)

        return thunkAPI.rejectWithValue(error.response?.data);
      } else {
        // Handle other errors
        console.log("error response", error.response);
        console.log("Error", error);
        toaster("warn", `${error.message} Check your network connection`);
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  }
);

export const { reset } = raffleSlice.actions;

export default raffleSlice.reducer;
