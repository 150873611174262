export const setDataInStorage = (key, data) => {
  const value = JSON.stringify(data);
  sessionStorage.setItem(key, value);
};

export const getDataInStorage = (key) => {
  const data = sessionStorage.getItem(key);
  return JSON.parse(data);
};

export const removeDataInStorage = (key) => {
  sessionStorage.removeItem(key);
};

export const clearStorage = () => {
  sessionStorage.clear();
};
