import React from "react";

function FooterSocial() {
  return (
    <>
      <div className="kab-socials pb-4">
        <a
          href="https://www.instagram.com/zanobidafrica/"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-instagram"></i>
        </a>
        <a
          href="https://www.facebook.com/zanobidafrica"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-facebook-square"></i>
        </a>
        <a
          href="https://twitter.com/zanobidafrica"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-twitter"></i>
        </a>
        <a
          href="https://www.tiktok.com/@zanobid"
          target="_blank"
          rel="noreferrer"
        >
          <i className="fab fa-tiktok"></i>
        </a>
        {/* <a href="#" target="_blank" rel="noreferrer">
          <i className="fab fa-tumblr-square"></i>
        </a> */}
      </div>
      <div className="kab-copyright">
        <a href="terms">Conditions of Use</a>
        <a href="policy">Privacy Notice</a>
        <a href="terms">Terms & Conditions</a>
        <div className="copy mt-2">
          ©Zanobid.com 2024, subsidiary of Zipawoof.
        </div>
      </div>
    </>
  );
}

export default FooterSocial;
