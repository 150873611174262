import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import logo2 from "../../assets/images/logo-2x.png";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { useDispatch, useSelector } from "react-redux";
import {
  getSubscriptionsPlanT,
  initiateSubscriptionT,
} from "../../redux/slice/user";
import { initiateSubscriptionsService } from "../../redux/services/user/user";
import { Circles } from "react-loader-spinner";

function SignupNext() {
  const [paymentLink, setPaymentLink] = useState("#");
  const dispatch = useDispatch();
  const { subscriptionPlans } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getSubscriptionsPlanT());
  }, []);

  const getLinked = async () => {
    const linkdata = await initiateSubscriptionsService({
      subscriptionPlanId: subscriptionPlans[0]?._id,
    });
    setPaymentLink(linkdata?.link);
  };

  useEffect(() => {
    if (subscriptionPlans.length > 0) {
      getLinked();
    }
  }, [subscriptionPlans]);

  return (
    <section style={{ height: "100vh" }} class="section-pd">
      <div class="section-pd text-center">
        <a href="/">
          <img src={logo} srcSet={`${logo2} 2x`} alt="Zanobid" />
        </a>
      </div>
      <div class="mb-3">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-4 col-11">
              <div class="za-bg-white p-3 kab-login">
                {paymentLink?.length > 2 ? (
                  <form>
                    <div class="mb-3">
                      <h4 class="main-title">How would you like to pay?</h4>
                      <a
                        href={paymentLink}
                        // onClick={() =>
                        //   handleFlutterPayment({
                        //     callback: (response) => {
                        //       console.log(response);
                        //       closePaymentModal();
                        //     },
                        //     onClose: () => {},
                        //   })
                        // }
                        class="pay-card"
                      >
                        <div class="row">
                          <div class="col-2 my-auto">
                            <svg
                              width="42"
                              height="42"
                              viewBox="0 0 42 42"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_601_492)">
                                <path
                                  d="M21 48C35.9117 48 48 35.9117 48 21C48 6.08831 35.9117 -6 21 -6C6.08831 -6 -6 6.08831 -6 21C-6 35.9117 6.08831 48 21 48Z"
                                  fill="url(#paint0_linear_601_492)"
                                />
                                <path
                                  d="M26.6097 19.1768C30.6982 15.9158 39.0479 4.16484 25.9763 6.24515C20.2754 7.08852 11.9257 12.4861 10.2558 18.727C9.39199 17.0403 8.41306 15.466 7.37654 13.8917C5.76418 11.7552 3.97906 13.4419 5.70659 15.5784C10.8276 21.6321 13.7801 29.1551 14.1139 37C14.517 33.233 13.8836 28.6225 12.3288 24.1246C13.0774 24.3495 13.9412 24.4619 14.6898 24.4619C17.3386 28.735 21.4271 32.3896 25.9763 34.0763C30.1799 35.7068 35.7656 34.751 33.4623 28.1727C32.253 24.5744 29.4314 21.4258 26.6097 19.1768ZM27.1856 7.76321C34.4412 6.97607 26.6673 15.8033 24.1336 17.4338C21.7726 15.9158 18.7782 14.5664 15.899 14.96C17.9721 12.3174 23.0395 8.26923 27.1856 7.76321ZM15.8414 16.8716C17.7993 16.7029 19.9299 17.7712 21.5999 18.8957C19.9875 19.6828 18.26 20.1326 16.4749 20.3013C13.826 20.3013 13.2501 17.0965 15.8414 16.8716ZM25.8611 30.5904C22.4636 29.6346 19.2389 26.9358 16.9355 24.237C18.8358 23.8434 20.7361 23.1125 22.4636 22.1005C23.0971 21.8194 23.7305 21.3696 24.3063 20.976C26.3218 22.7752 28.4524 25.1928 29.2586 27.9478C30.0648 30.9277 27.8766 31.1526 25.8611 30.5904Z"
                                  fill="#F5A623"
                                />
                              </g>
                              <defs>
                                <linearGradient
                                  id="paint0_linear_601_492"
                                  x1="21"
                                  y1="-29.22"
                                  x2="21"
                                  y2="48"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="white" />
                                </linearGradient>
                                <clipPath id="clip0_601_492">
                                  <rect
                                    width="42"
                                    height="42"
                                    rx="15"
                                    fill="white"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                          <div class="col-9 my-auto">
                            <h5>Pay with Flutterwave</h5>
                          </div>
                          <div class="col-1 p-0 my-auto">
                            <svg
                              width="9"
                              height="14"
                              viewBox="0 0 9 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M0.299072 11.8768L5.3863 6.78953L0.299072 1.72879L1.86233 0.165527L8.48633 6.78953L1.86233 13.4135L0.299072 11.8768Z"
                                fill="#37475A"
                              />
                            </svg>
                          </div>
                        </div>
                      </a>
                    </div>
                  </form>
                ) : (
                  <div className="d-flex justify-content-center my-4">
                    <Circles
                      height="50"
                      width="50"
                      color="#212529"
                      ariaLabel="circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignupNext;
