import axios from "axios";
import { getDataInStorage } from "../util/storage";
import { BASE_URL } from "./endpoints";

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
});

export const axiosFilePrivate = axios.create({
  baseURL: BASE_URL,
});

axiosPrivate.interceptors.request.use(
  (config) => {
    const token = getDataInStorage("accesstoken");

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosFilePrivate.interceptors.request.use(
  (config) => {
    const token = getDataInStorage("accesstoken");

    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers["Content-Type"] = "multipart/form-data";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
