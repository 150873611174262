import React, { useEffect } from "react";
import nucleus from "../../assets/images/icons/nucleuswave.svg";
import SignupForm from "../../components/forms/signup/signup";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

function Signup() {
  const navigate = useNavigate();
  const { phonenumber } = useParams();
  const { signupSuccessful } = useSelector((state) => state.user);

  useEffect(() => {
    if (signupSuccessful) {
      navigate("/sms");
    }
  }, [signupSuccessful]);

  return (
    <section style={{ height: "100vh" }} class="section-pd">
      <div class="section-pd text-center">
        <a href="/">
          <img style={{ width: "15%" }} src={nucleus} alt="nucleuswave" />
        </a>
      </div>
      <div class="mb-3">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-4 col-11">
              <div class="za-bg-white p-3 kab-login">
                <SignupForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Signup;
