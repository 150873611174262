import { toast } from 'react-toastify';

export const toaster = (type, message) => {
  toast.clearWaitingQueue();
  if (type === 'success') {
    toast.success(message, {
      toastId: 'success',
    });
  }

  if (type === 'info') {
    toast.info(message, {
      toastId: 'info',
    });
  }

  if (type === 'warn') {
    toast.warn(message, {
      toastId: 'warn',
    });
  }

  if (type === 'error') {
    toast.error(message, {
      toastId: 'error',
    });
  }
};
