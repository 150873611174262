import React from "react";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";

const Terms = () => {
  return (
    <>
      <Header />
      <div class="abx-fixed-top">
        <section class="inner-hero text-center">
          <div class="container">
            <div class="row">
              <div class="col-xl-9 mx-auto">
                <h1 class="mb-5">Terms & Conditions</h1>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section class="inner-body terms bg-white and conditions">
        <div class="container">
          <div class="row  justify-content-md-center">
            <div class="col-md-8">
              <h5>
                GENERAL
                <br />
                <br />
                [I] The following Terms & Conditions shall apply to the
                relationship between Zanobid Limited and the end user [“You” or
                “Your”] and shall regulate all aspects of use of the website
                www.Zanobid.com and any of its sub-domains. By using Zanobid’s
                services, you accept the following Terms and Conditions, as well
                as the manner in which Zanobid operates. These Terms &
                Conditions constitute the entire agreement between Zanobid and
                you, and supersede and replace all prior commitments,
                undertakings or representations, whether written or oral,
                between you and Zanobid with respect to your use of Zanobid.
                <br />
                <br />
                [ii] Zanobid reserves the right to change these Terms &
                Conditions at any time without notice to you.
                <br />
                <br />
                <br />
                ACCEPTANCE OF TERMS & CONDITIONS
                <br />
                <br />
                [i] By accepting the Terms & Conditions and/or registering with
                Zanobid, you confirm that you have read, understand and accept
                without reservation to be bound by the terms and conditions of
                Zanobid. If you do not agree to the Terms & Conditions, please
                do not use Zanobid.
                <br />
                <br />
                <br />
                ELIGIBILITY
                <br />
                <br />
                [i] By accepting the Terms & Conditions and/or registering on
                Zanobid, you confirm that you are at least 18 years of age and
                at least age of majority subject to the laws of the state, city
                or other legal entity; therefore you can engage in a binding
                contract. By using Zanobid, you warrant that you have the right,
                authority, and capacity to enter into this agreement and to
                abide by all of the Terms & Conditions. By registering on
                Zanobid, the user declares that they are legally capable of
                entering into binding contracts. [ii] Zanobid employees and
                their family members may not, under any circumstances,
                participate in Zanobid auctions. [iii] Zanobid products and
                services are offered exclusively to private users and not to
                commercial or semi-commercial retailers. [iv] A user may not use
                any external software to automatically place bids in the
                auctions and Zanobid reserves the right, at its sole discretion,
                to deactivate any user’s account based on suspicion of the use
                of such software.
                <br />
                <br />
                <br />
                REGISTRATION
                <br />
                <br />
                [i] Your user account may be used only by you and not by anyone
                else and you warrant that all information that you provide to
                Zanobid for the purpose of registration is true, complete, and
                correct, and that you shall immediately notify us of any change
                of such information. [ii] You are allowed to have only one
                account. If you attempt to open more than one account, all
                accounts you try to open may be blocked or closed and any
                winnings forfeited thereof. [iii] You will be required to choose
                a unique username [which must not in any way be offensive,
                indecent, insulting or intended to deceive or mislead or
                impersonate other users or persons.
              </h5>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Terms;
