import React from "react";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";

const HowItWorks = () => {
  return (
    <>
      <Header />

      <div class="abx-fixed-top">
        <section class="inner-hero text-center">
          <div class="container">
            <div class="row">
              <div class="col-xl-9 mx-auto">
                <h1 class="mb-5">How It Works</h1>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section class="inner-body bg-white faq how-it-works">
        <div class="container">
          <div class="row  justify-content-md-center">
            <div class="col-md-8">
              <h4>How Zanobid Works:</h4>
              <h5>
                Bidders who are interested in winning amazing prizes must
                subscribe to Zanobid and pay a daily fee of ₦50, ₦100 or ₦200 (MTN Airtime). By doing
                so, users gain access to daily auctions featuring over 200
                items up for grabs monthly. The Zanobid auctions are available every day
                from 8:00 pm. The great thing about these auctions is that
                anyone can win. All you need to do is subscribe with  ₦50, ₦100 or ₦200 (MTN Airtime),
                participate in live auctions, and be quick to click the "BID
                NOW" button consistently until you come out on top. With
                Zanobid, the opportunity to win exciting prizes is at your
                finger tips, so subscribe today and start bidding!
                <br />
                <br />
                <b class="highlight">How do I win on Zanobid:</b> <br />
                The person who clicks on Bid "BID NOW" the fastest and most
                consistently before the auction ends at 0:00:00 wins.
              </h5>
              <br />
              <br />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default HowItWorks;
