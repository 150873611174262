import React, { useEffect } from "react";
import logo from "../../assets/images/logo.png";
import logo2 from "../../assets/images/logo2.png";
import SignupForm from "../../components/forms/signup/signup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import GatewaySignupForm from "../../components/forms/signup/gateway-signup";
import { getSubscriptionsPlanT } from "../../redux/slice/user";

function SignupGateway() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { phonenumber } = useParams();
  const { signupSuccessful } = useSelector((state) => state.user);

  useEffect(() => {
    if (signupSuccessful) {
      navigate("/subscription-plan");
    }
  }, [signupSuccessful]);

  useEffect(() => {
    dispatch(getSubscriptionsPlanT());
  }, []);

  return (
    <section style={{ height: "100vh" }} class="section-pd">
      <div class="section-pd text-center">
        <a href="/">
          <img src={logo} srcSet={`${logo2} 2x`} alt="Zanobid" />
        </a>
      </div>
      <div class="mb-3">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-sm-4 col-11">
              <div class="za-bg-white p-3 kab-login">
                <GatewaySignupForm
                  phonenumber={phonenumber && `+${phonenumber}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignupGateway;
