import React from 'react';
import FooterSocial from './footer-social';

function FooterLogin() {
  return (
    <footer className="kab-footer kab-bg-darkest za-white">
      <div className="bottom-footer section-pd text-center">
        <FooterSocial />
      </div>
    </footer>
  );
}

export default FooterLogin;
