export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const LOGIN = `${BASE_URL}users/login`;
export const GET_AUCTION = `${BASE_URL}auctions`;
export const PLACE_BID = `${BASE_URL}bidding/placebid`;
export const JOIN_AUCTION = `${BASE_URL}auctions/joinauction`;
export const GET_SINGLE_AUCTION = `${BASE_URL}auctions`;
export const GET_CURRENT_WINNER = `${BASE_URL}auctions/currentwinner`;
export const GET_FINAL_WINNER = `${BASE_URL}auctions/finalwinner`;
export const SELECTED_AUCTION_ID = "642499235c51b2a3db86a40a";
export const EXPORTED_W_USERNAME = "olumide5";
export const SIGNUP = `${BASE_URL}users/register`;
export const GET_SUBSCRIPTION_PLAN = `${BASE_URL}subscriptions/plans`;
export const INITIATE_SUBSCRIPTION = `${BASE_URL}subscriptions/initiate/subscriptionpayment`;
export const SUBSCRIPTION_DETAILS = `${BASE_URL}subscriptions/mycurrentsubscription`;
export const LAST_AUCTION = `${BASE_URL}auctions/lastclosed/fetch`;
export const GET_RAFFLE_WINNER = `${BASE_URL}auctions/raffle/50`;
export const GET_PAST_RAFFLE_WINNER = `${BASE_URL}auctions/pastwinners/raffle/fetch`;
export const GET_PAST_AUCTION_WINNER = `${BASE_URL}auctions/pastwinners/auction/fetch`;
export const GET_CURRENT_TIME = `${BASE_URL}auctions/server/time`;
export const FORGOT_PASSWORD = `${BASE_URL}users/forgot-password`;
export const GET_ACTIVE_BIDDER = `${BASE_URL}admin/get-active-subscribers`;
export const CREATE_COLLECTION = `${BASE_URL}message/save-collection`;
export const SEND_MESSAGE = `${BASE_URL}message/send-message`;
export const GET_COLLECTION = `${BASE_URL}message/get-collection`;
export const GET_STATS = `${BASE_URL}message/get-stat`;
export const DOWNLOAD_REPORT = `${BASE_URL}message/download`;
// export const CREATE_COLLECTION = `${BASE_URL}message/save-collection`;
