import React, { useEffect, useState } from "react";
import FooterLogin from "../../components/footer/footer-login";
import FBAICON from "../../assets/images/fba.svg";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import {
  getAuctionT,
  getServerTimeT,
  getlastAuctionT,
} from "../../redux/slice/auction";
import { useDispatch, useSelector } from "react-redux";
import {
  Auction100,
  Auction200,
  Auction2k,
  Auction50,
  Auction5k,
} from "../../components/auction/auction";
import { useAuth } from "../../hooks/auth";
import TopItems from "../../components/Top-items/topItems";
import Upcoming from "../../components/Upcoming/upcoming";
import GetReadyModal from "../../components/modal/getReadyModal";
import { Circles } from "react-loader-spinner";
import {
  SubscriptionDetailsT,
  getSubscriptionsPlanT,
} from "../../redux/slice/user";
import { Link, useNavigate } from "react-router-dom";
import { useNetworkStatus } from "../../network/useNetworkStatus";
import { Offline, Online } from "react-detect-offline";
import { Detector } from "react-detect-offline";
import Raffle from "../../components/Raffle/raffle";
import AuctionWinnerSection from "../../components/auctionWinnerSection/auctionWinnerSection";
import TestGameModal from "../../components/modal/test-modal";
import ReactGA from "react-ga4";

function Home() {
  ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // modal state
  const {
    auction50,
    auction100,
    auction200,
    auction2k,
    auction5k,
    isLoading,
    serverCurrentTime,
  } = useSelector((state) => state.auction);
  const { subscriptionPlans } = useSelector((state) => state.user);
  const { accesstoken, hasPaidOnce } = useSelector((state) => state.user);
  const [show, setShow] = useState(false);
  const [getReadyTime, setGetReadyTime] = useState();
  const [checkTime, setCheckTime] = useState(false);
  const [showTestGame, setShowTestGame] = useState(false);

  const auth = useAuth();

  useEffect(() => {
    dispatch(getSubscriptionsPlanT());
    dispatch(getlastAuctionT());
  }, []);

  const getTime = () => {
    if (!checkTime) {
      dispatch(getServerTimeT());
    }
  };

  useEffect(() => {
    const intervalId = setInterval(getTime, 30000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (accesstoken) {
      dispatch(SubscriptionDetailsT());
    }
  }, [accesstoken, auction50]);

  const handlegetReadyShow = () => {
    setShow(true);
  };

  const handlegetReadyClose = () => {
    setShow(false);
  };

  const handleTestGameOpen = () => {
    setShowTestGame(true);
  };

  const handleTestGameClose = () => {
    setShowTestGame(false);
  };

  return (
    <div>
      <GetReadyModal
        show={show}
        getReadyTime={getReadyTime}
        handleClose={handlegetReadyClose}
      />
      <TestGameModal show={showTestGame} handleClose={handleTestGameClose} />

      <div className="">
        <Header auth={auth} />
        <section style={{ background: "#fff" }} class="za-top-up">
          <div class="container">
            <div class="row justify-content-md-center">
              <div class="col-sm-4">
                <h4 class="main-title">Select data to View </h4>

                <Link to="/active-subscribers" class="pay-card">
                  <div class="row">
                    <div class="col-11 my-auto">
                      <h5> Active subscribers </h5>
                    </div>
                    <div class="col-1 p-0 my-auto">
                      <svg
                        width="9"
                        height="14"
                        viewBox="0 0 9 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.299072 11.8768L5.3863 6.78953L0.299072 1.72879L1.86233 0.165527L8.48633 6.78953L1.86233 13.4135L0.299072 11.8768Z"
                          fill="#37475A"
                        />
                      </svg>
                    </div>
                  </div>
                </Link>
                <a class="pay-card">
                  <div class="row">
                    <div class="col-11 my-auto">
                      <h5> Pay with Flutterwave </h5>
                    </div>
                    <div class="col-1 p-0 my-auto">
                      <svg
                        width="9"
                        height="14"
                        viewBox="0 0 9 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.299072 11.8768L5.3863 6.78953L0.299072 1.72879L1.86233 0.165527L8.48633 6.78953L1.86233 13.4135L0.299072 11.8768Z"
                          fill="#37475A"
                        />
                      </svg>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
