import { useEffect, useState } from 'react';
import { toaster } from '../notification/toaster';

export const useNetworkStatus = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const handleConnectionChange = () => {
    // console.log(navigator.onLine);
    setIsOnline(navigator.onLine);
  };

  if (!isOnline) {
    toaster('warn', 'you are offline, check your internet connection');
  }

  useEffect(() => {
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    };
  }, []);
};
