import React from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";

function About() {
  return (
    <>
      <Header />
      <div class="abx-fixed-top">
        <section class="inner-hero text-center">
          <div class="container">
            <div class="row">
              <div class="col-xl-9 mx-auto">
                <h1 class="mb-5">About Us</h1>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section class="inner-body bg-white about-us">
        <div class="container">
          <div class="row  justify-content-md-center">
            <div class="col-md-8">
              <h5>
                Welcome to Zanobid. <br />
                <br />
                Zanobid is a subsidiary of ZipAwoof Limited, a Nigerian
                enterprise based in Lagos and founded by a group of seasoned
                investors and successful business people. Zanobid is a new and
                exciting auction platform where you can win factory-sealed,
                unique products like cars, iPhones, laptops, washing machines,
                and so on with just a daily subscription of ₦50, ₦100 or ₦200 (MTN Airtime).
                <br />
                <br />
                With Zanobid, you experience a completely new and fun way of
                online bidding.
                <br />
                <br />
                Our mantra is this: "Why spend a fortune on a brand new product
                if you can get it with just ₦50, ₦100 or ₦200 (MTN Airtime) on Zanobid?" We offer you the
                opportunity to get the products of your dreams for the
                ridiculously low price of  ₦50, ₦100 or ₦200 (MTN Airtime)
                <br />
                <br />
                To get started, look at our homepage to see what products are up
                for auction right now, and if something seems interesting,
                subscribe with  ₦50, ₦100 or ₦200 (MTN Airtime) and bid for it!
              </h5>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default About;
